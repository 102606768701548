import React, { useState, useEffect, useRef } from "react";
import CardBody from "components/CardBody";
import Header from "components/Header";
import { RadioButton } from "primereact/radiobutton";
import SingleIncrement from "./components/SingleIncrement";
import Switch from "components/Switch";
import OptionItem from "./components/OptionItem";
import { Checkbox } from "primereact/checkbox";
import { emptyItem } from "utils/emptyItem";

// Very name dependent
// Addons are limited to one option

const PackageContainer = ({
  items,
  category,
  setSelectedCategory,
  selectedCategory,
  formatCurrency,
  subCategory,
  toggle, // false is essentials, true is complete
}) => {
  const categoryKey = category.replace(/\s/g, "");
  const [visible, setVisible] = useState({ id: false }); // used for tooltip
  const [val, setVal] = useState(0);
  const [selectAddOn, setSelectAddOn] = useState({ checked: false, item: {} });
  const [toggleState, setToggleState] = useState(false);

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };
  const findWindow = (data, windowType) => {
    return items[data.services]?.[windowType].find(
      (window) =>
        window.metadata__windows_storm_pane === data.storm &&
        window.metadata__windows_french_pane === data.french
    );
  };
  // used to see if user changed increment with addons
  const prevVal = usePrevious(val);
  const preToggle = usePrevious(toggleState);

  useEffect(() => setToggleState(toggle), [toggle]);

  // RELOOK
  useEffect(() => {
    // setting initial upper level defaults with increments KEY DEPENDENT
    selectedCategory[categoryKey]?.metadata__price_category !== "Lawn Care" &&
    selectedCategory[categoryKey]?.metadata__quantity !== undefined
      ? setVal(selectedCategory[categoryKey]?.metadata__quantity)
      : setVal(0);

    // resetting checked for lawn if the upgrade option KEY DEPENDENT
    selectedCategory[categoryKey]?.product_name === "Dreamer Deluxe Upgrade" &&
      setSelectAddOn({ checked: false, item: {} });
  }, [selectedCategory[categoryKey]]);

  useEffect(() => {
    // setting up saved add ons- if only one addon
    selectedCategory[`${categoryKey}-addon`]?.is_add_on
      ? setSelectAddOn({
          checked: true,
          item: selectedCategory[`${categoryKey}-addon`],
        })
      : setSelectAddOn({ checked: false, item: {} });
  }, [selectedCategory[`${categoryKey}-addon`]]);

  useEffect(() => {
    val > 0
      ? setSelectedCategory({
          ...selectedCategory,
          [categoryKey]: categoryKey?.includes("Windows")
            ? findWindow(
                {
                  services: val,
                  storm: selectedCategory[categoryKey]
                    ?.metadata__windows_storm_pane
                    ? selectedCategory[categoryKey]
                        ?.metadata__windows_storm_pane
                    : false,
                  french: selectedCategory[categoryKey]
                    ?.metadata__windows_french_pane
                    ? selectedCategory[categoryKey]
                        ?.metadata__windows_french_pane
                    : false,
                },

                subCategory
              )
            : items[val],
        })
      : setSelectedCategory({
          ...selectedCategory,
          [categoryKey]: { ...emptyItem },
        });
  }, [val]);

  useEffect(() => {
    // reset add on option when user changes quantity numbers
    if (toggleState === preToggle && prevVal !== 0 && prevVal !== val) {
      setSelectAddOn({ checked: false, item: {} });
    }
  }, [prevVal, val]);

  // fix code when there are more addons
  useEffect(() => {
    const addonKey = `${categoryKey}-addon`;
    selectAddOn?.checked
      ? setSelectedCategory({
          ...selectedCategory,
          [addonKey]: selectAddOn?.item,
        })
      : setSelectedCategory({
          ...selectedCategory,
          [addonKey]: { ...emptyItem },
        });
  }, [selectAddOn?.checked]);

  return (
    <CardBody className="flex flex-col justify-start w-full package-card-gray mb-4">
      <div className="w-full flex flex-col items-start">
        <Header $size="h5" $weight="700">
          {category}
        </Header>
        <Switch condition={category}>
          <Switch.Case when={"HVAC, Plumbing, Electrical & Rooter"}>
            <div className="flex flex-col">
              <SingleIncrement
                setVal={setVal}
                val={val}
                label={"Number of HVAC systems"}
                subtext={"1 furnace and 1 AC = 1 system"}
                max={4}
                setVisible={setVisible}
                visible={visible}
                priceId={items[val]?.priceId}
                popup={items[val]?.popup_description}
              />
              {val > 0 && (
                <div className="flex flex-col gap-y-2">
                  <OptionItem
                    item={items[val]}
                    formatCurrency={formatCurrency}
                  />
                  <span className="text-sm italic font-bold">Add on</span>

                  {items[val]?.addons && (
                    <div className="flex flex-row gap-x-2">
                      {items[val]?.addons?.map((addon, index) => (
                        <div className="flex flex-row" key={index}>
                          <Checkbox
                            checked={selectAddOn?.checked}
                            onChange={({ checked }) =>
                              setSelectAddOn({ checked: checked, item: addon })
                            }
                          />
                          <OptionItem
                            item={addon}
                            formatCurrency={formatCurrency}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          </Switch.Case>
          <Switch.Case when={"Gutters"}>
            <SingleIncrement
              setVal={setVal}
              val={val}
              label={"Services per year"}
              max={4}
              setVisible={setVisible}
              visible={visible}
              priceId={items[val]?.price_id}
              popup={items[val]?.popup_description}
              setSelectedCategory={setSelectedCategory}
              selectedCategory={selectedCategory}
            />

            {val > 0 && (
              <div className="flex flex-col gap-y-2">
                <OptionItem item={items[val]} formatCurrency={formatCurrency} />
                <span className="text-sm italic font-bold">Add on</span>

                {items[val]?.addons && (
                  <div className="flex flex-row gap-x-2">
                    {items[val]?.addons?.map((addon, index) => (
                      <div className="flex flex-row" key={index}>
                        <Checkbox
                          checked={selectAddOn?.checked}
                          onChange={({ checked }) =>
                            setSelectAddOn({ checked: checked, item: addon })
                          }
                        />
                        <OptionItem
                          item={addon}
                          formatCurrency={formatCurrency}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </Switch.Case>
          <Switch.Case when={"Dryer Vent Cleaning"}>
            <div className="flex flex-col">
              <SingleIncrement
                setVal={setVal}
                val={val}
                label={"Services per year"}
                max={2}
                setVisible={setVisible}
                visible={visible}
                priceId={items[val]?.price_id}
                popup={items[val]?.popup_description}
              />
              {val > 0 && (
                <OptionItem item={items[val]} formatCurrency={formatCurrency} />
              )}
            </div>
          </Switch.Case>
          <Switch.Case when={"Exterior Hard Surface Wash"}>
            <SingleIncrement
              setVal={setVal}
              val={val}
              label={"Number of surfaces"}
              subtext={"Each non adjacent surfaces that require power washing"}
              max={4}
              setVisible={setVisible}
              visible={visible}
              priceId={items[val]?.price_id}
              popup={items[val]?.popup_description}
            />
            {val > 0 && (
              <OptionItem item={items[val]} formatCurrency={formatCurrency} />
            )}
          </Switch.Case>
          <Switch.Case when={"Interior Windows"}>
            <SingleIncrement
              setVal={setVal}
              val={val}
              label={"Services per year"}
              max={1}
              setVisible={setVisible}
              visible={visible}
              priceId={selectedCategory[`${categoryKey}`]?.price_id}
              popup={selectedCategory[`${categoryKey}`]?.popup_description}
              setSelectedCategory={setSelectedCategory}
              selectedCategory={selectedCategory}
            />
            {val > 0 && (
              <div className={`flex flex-col mb-4`}>
                <div className={`flex flex-col mb-2 items-start`}>
                  <span className="text-sm">
                    Choose any of the following that apply to your home
                  </span>
                </div>
                <div
                  className={`w-full flex flex-col lg:flex-nowrap md:flex-wrap md:flex-row md:gap-x-5 items-start md:justify-start`}
                >
                  {val > 0 &&
                    items[val]?.["interior"]
                      ?.sort((a, b) => a.price - b.price)
                      .map((item, idx) => (
                        <div
                          key={idx}
                          className={`flex flex-row w-full mb-2 md:w-auto`}
                        >
                          <RadioButton
                            inputId={item?.price_id}
                            name={category}
                            value={item}
                            onChange={(e) => {
                              setSelectedCategory({
                                ...selectedCategory,
                                [`${categoryKey}`]: item,
                              });
                            }}
                            checked={
                              selectedCategory[`${categoryKey}`]?.price_id ===
                              item?.price_id
                            }
                          />
                          <OptionItem
                            item={item}
                            formatCurrency={formatCurrency}
                          />
                        </div>
                      ))}
                </div>
              </div>
            )}
          </Switch.Case>
          <Switch.Case when={"Exterior Windows"}>
            <SingleIncrement
              setVal={setVal}
              val={val}
              label={"Services per year"}
              max={2}
              setVisible={setVisible}
              visible={visible}
              priceId={selectedCategory[`${categoryKey}`]?.price_id}
              popup={selectedCategory[`${categoryKey}`]?.popup_description}
              setSelectedCategory={setSelectedCategory}
              selectedCategory={selectedCategory}
            />
            {val > 0 && (
              <>
                <div className={`flex flex-col mb-2 items-start`}>
                  <span className="text-sm">
                    Choose any of the following that apply to your home
                  </span>
                </div>
                <div className={`flex flex-col mb-4`}>
                  <div
                    className={`w-full flex flex-col lg:flex-nowrap md:flex-wrap md:flex-row md:gap-x-5 items-start md:justify-start`}
                  >
                    {val > 0 &&
                      items[val]?.["exterior"]
                        ?.sort((a, b) => a.price - b.price)
                        .map((item, idx) => (
                          <div
                            key={idx}
                            className={`flex flex-row w-full md:w-auto`}
                          >
                            <RadioButton
                              inputId={item?.price_id}
                              name={category}
                              value={item}
                              onChange={(e) => {
                                setSelectedCategory({
                                  ...selectedCategory,
                                  [`${categoryKey}`]: item,
                                });
                              }}
                              checked={
                                selectedCategory[`${categoryKey}`]?.price_id ===
                                item?.price_id
                              }
                            />
                            <OptionItem
                              item={item}
                              formatCurrency={formatCurrency}
                            />
                          </div>
                        ))}
                  </div>
                </div>
                <span className="text-sm italic font-bold">Add on</span>

                {/* ADDON is the same for all items so only focus on one add on */}
                {items[val]?.["exterior"]?.[0]?.addons && (
                  <div className="flex flex-row gap-x-2">
                    {items[val]?.["exterior"]?.[0]?.addons.map(
                      (addon, index) => (
                        <div className="flex flex-row" key={index}>
                          <Checkbox
                            checked={selectAddOn?.checked}
                            onChange={({ checked }) => {
                              setSelectAddOn({ checked: checked, item: addon });
                            }}
                          />
                          <OptionItem
                            item={addon}
                            formatCurrency={formatCurrency}
                          />
                        </div>
                      )
                    )}
                  </div>
                )}
              </>
            )}
          </Switch.Case>
          <Switch.Case when={"Lawn Care"}>
            <div>
              <div
                className={`w-full flex flex-col lg:flex-nowrap md:flex-wrap md:flex-row md:gap-x-5 items-start md:justify-start`}
              >
                {Object.values(items)?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`flex flex-row w-full mb-2 md:w-auto`}
                    >
                      <div className="flex flex-col">
                        <div className="flex flex-row">
                          <RadioButton
                            inputId={item?.price_id}
                            name={category}
                            value={item}
                            onChange={(e) => {
                              setSelectedCategory({
                                ...selectedCategory,
                                [categoryKey]: item,
                              });
                            }}
                            checked={
                              selectedCategory[categoryKey]?.price_id ===
                              item?.price_id
                            }
                          />
                          <OptionItem
                            item={item}
                            formatCurrency={formatCurrency}
                            visible={visible}
                            setVisible={setVisible}
                            displayInfoIcon={true}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}

                <div
                  className={`flex flex-row w-full mb-2 items-center md:w-auto`}
                >
                  <RadioButton
                    inputId={0}
                    name={category}
                    value={0}
                    onChange={(e) => {
                      setSelectedCategory({
                        ...selectedCategory,
                        [categoryKey]: emptyItem,
                      });
                    }}
                    checked={selectedCategory[categoryKey]?.price_id === 0}
                  />
                  <div className="flex flex-col w-full">
                    <div className="w-9/10 flex flex-row items-start justify-between md:justify-start md:gap-x-2.5">
                      <label htmlFor="None" className="ml-2">
                        <Header $size="h6" $weight={700} $align="left">
                          None
                        </Header>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {/** Addon is only for essentials or None and only one */}
              {selectedCategory[categoryKey]?.product_name !==
                "Dreamer Deluxe Upgrade" &&
                items[1]?.addons && (
                  <div className="flex flex-col gap-y-2">
                    <span className="text-sm italic font-bold">Add on</span>
                    <div className="flex flex-row">
                      <Checkbox
                        checked={selectAddOn?.checked}
                        onChange={({ checked }) =>
                          setSelectAddOn({
                            checked: checked,
                            item: items[1]?.addons && items[1]?.addons[0],
                          })
                        }
                      />
                      <OptionItem
                        item={items[1]?.addons && items[1]?.addons[0]}
                        formatCurrency={formatCurrency}
                      />
                    </div>
                  </div>
                )}
            </div>
          </Switch.Case>
          <Switch.Default>
            <div
              className={`w-full flex flex-col lg:flex-nowrap md:flex-wrap md:flex-row md:gap-x-5 items-start md:justify-start`}
            >
              {Object.values(items)?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`flex flex-row w-full mb-2 md:w-auto`}
                  >
                    <RadioButton
                      inputId={item?.price_id}
                      name={category}
                      value={item}
                      onChange={(e) => {
                        setSelectedCategory({
                          ...selectedCategory,
                          [categoryKey]: item,
                        });
                      }}
                      checked={
                        selectedCategory[categoryKey]?.price_id ===
                        item?.price_id
                      }
                    />
                    <OptionItem
                      item={item}
                      formatCurrency={formatCurrency}
                      visible={visible}
                      setVisible={setVisible}
                      displayInfoIcon={true}
                    />
                  </div>
                );
              })}
              <div
                className={`flex flex-row w-full mb-2 items-center md:w-auto`}
              >
                <RadioButton
                  inputId={0}
                  name={category}
                  value={0}
                  onChange={(e) => {
                    setSelectedCategory({
                      ...selectedCategory,
                      [categoryKey]: emptyItem,
                    });
                  }}
                  checked={selectedCategory[categoryKey]?.price_id === 0}
                />
                <div className="flex flex-col w-full">
                  <div className="w-9/10 flex flex-row items-start justify-between md:justify-start md:gap-x-2.5">
                    <label htmlFor="None" className="ml-2">
                      <Header $size="h6" $weight={700} $align="left">
                        None
                      </Header>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </Switch.Default>
        </Switch>
      </div>
    </CardBody>
  );
};
export default PackageContainer;
