import React, { useState, useRef, useEffect, useContext } from "react";
import { OnboardContext } from "providers/OnboardContext";
import { Context } from "providers/Context";
import { useNavigate } from "react-router-dom";
import { APILoader } from "@googlemaps/extended-component-library/react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { useForm, Controller } from "react-hook-form";
import { houseSizeOptions, lawnSizeOptions } from "../LocationForm/data";
import { InputText } from "primereact/inputtext";
import StyledLabel from "components/InputField/components/StyledLabel";
import StyledErrorMsg from "components/InputField/components/StyledErrorMsg";



const LocationConfirmForm = ({ onSubmit, handleOnChangeConfirmAddress, address, setAddress, disabledContinue }) => {

  const { openInfoSnackbar, openErrorSnackbar } = useContext(Context);
  const { locationResponse, getOnboardData, saveOnboardData } = useContext(OnboardContext);
  const [ locationBlur, setLocationBlur ] = useState(false);
  const [ loadingContinue, setLoadingContinue ] = useState(false);
  const navigate = useNavigate();
  
  const initialValues = {
    houseSize: (houseSizeOptions.find(option => option.code === locationResponse?.house_size) || getOnboardData("ohs")),
    lawnSize: (locationResponse?.sqft?.LAWN),
    locationAddress: (locationResponse?.address || getOnboardData("ora")) ?? "",
  };

  const { handleSubmit, control, setValue, getValues, formState, formState: { errors }, } = useForm({ defaultValues: { ...initialValues }, });
  const formatSqft = (value) => {
    return Number(Number(value).toFixed(2)).toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  // used for google autocomplete
  const addressInputRef = useRef();
  let autoCompleteRef = useRef();
  let suggestionRef = useRef();
  let tokenRef = useRef();
  let timerId = useRef();

  const options = {
    componentRestrictions: { country: "us" },
    fields: ["formatted_address", "place_id"],
  };

  const startVerifyingDelay = async () => {
    clearTimeout(timerId.current); // Clear the previous timer if it exists
    setLoadingContinue(true);

    timerId.current = setTimeout(async () => { // Set a new timer and store its ID
      try{
        let request = {
          input: addressInputRef.current.value,
          language: "en-US",
          region: "us",
          sessionToken: tokenRef.current,
        };

        let response = await suggestionRef.current.fetchAutocompleteSuggestions(request);
        if(response?.suggestions?.length > 0 && response?.suggestions?.length < 2){
          let place = response?.suggestions[0]?.placePrediction?.toPlace();
          let responseAddress = (await place?.fetchFields({
            fields: ["formattedAddress"],
          })).place.Eg.formattedAddress + ", USA"

          if(responseAddress){
            responseAddress = responseAddress.endsWith(", USA") ? responseAddress : responseAddress + ", USA";
            addressInputRef.current.value = responseAddress;
            handleAddressInput(responseAddress);
            openInfoSnackbar("Address auto-completed, please verify");
          }else{
            throw new Error("No address found");
          }

        }else if(!response?.suggestions || response?.suggestions?.length === 0){
          throw new Error("No address found");
        }else if(response?.suggestions?.length > 1){
          throw new Error("Multiple Addresses found, please use dropdown selector");
        }else{
          throw new Error("An unknown error has occured");
        }
        
      }catch(e){
        setAddress(null);
        setValue("locationAddress", "");
        if(e.message.includes("error")){
          openErrorSnackbar(e.message);
        }else{
          openInfoSnackbar(e.message);
        }
      }finally{
        setLoadingContinue(false);
      }
    }, 2000);
  }

  const handleAddressInput = (newAddress) => {
    if (newAddress) {
      // eslint-disable-next-line
      const [street, city, stateWithZip, country] = newAddress?.split(",");
      const [state, zipCode] = stateWithZip?.trimStart()?.split(" ");
      // Zipcode sometimes comes up empty
      if (zipCode !== undefined) {
        const split_address = { city: city?.trimStart(), state, street_address: street?.trimStart(), postal_code: zipCode };

        saveOnboardData({"ora": newAddress});
        saveOnboardData({"oa": split_address});

        setAddress({
          full_address: newAddress,
          split_address: split_address,
        });

        setValue("locationAddress", newAddress);
        handleOnChangeConfirmAddress({full_address: newAddress, split_address: split_address, houseSize: getValues().houseSize, lawnSize: getValues().lawnSize});
      }
    }
  };

  const clearAddressInput = () => {
    if(addressInputRef){
      setAddress({});
      setValue("locationAddress", "");
      addressInputRef.current.value = "";
    }
  }
  
  const setUpAutoComplete = async () => {
    const { Autocomplete } = await APILoader.importLibrary("places");
    const { AutocompleteSessionToken, AutocompleteSuggestion } = await APILoader.importLibrary("places");
    tokenRef.current = new AutocompleteSessionToken();
    suggestionRef.current = AutocompleteSuggestion;
    autoCompleteRef = new Autocomplete(addressInputRef?.current, options);
    autoCompleteRef.addListener("place_changed", async () => {
      clearTimeout(timerId.current);
      const result = await autoCompleteRef.getPlace();
      if (result?.place_id) {
        handleAddressInput(result?.formatted_address);
        setLoadingContinue(false);
      } else {
        setAddress(null);
        setValue("locationAddress", "");
      }
    });
  };

  useEffect(() => {
    setUpAutoComplete();
    // eslint-disable-next-line
  }, []);

  return (
    <form id="confirm-location-form" onSubmit={handleSubmit((formData) => {
      onSubmit({
        ...formData,
        ...address,
      });
    })} className="flex flex-col items-center">
      {/*INCLUDED IN LOADER CALL ABOVE? <APILoader apiKey={process.env.REACT_APP_MAPS_API_KEY} />*/}
      <div className="flex flex-col gap-y-5 items-start w-full">
        <Controller
          control={control}
          name={"locationAddress"}
          rules={{
            required: "Valid address is required",
          }}
          render={({ field: { name } }) => (
            <>
              <div className="flex w-full flex-col gap-y-1.5">
                <StyledLabel>Address</StyledLabel>
                <div className={`p-inputgroup flex-1`}>
                  <InputText
                    name={name}
                    placeholder=""
                    onChange={(e) => {
                      if(getValues().locationAddress){
                        setValue("locationAddress", "");
                      }
                      startVerifyingDelay();
                    }}
                    aria-label="Address Details"
                    defaultValue={initialValues?.locationAddress}
                    type="text"
                    className={`p-inputtext-sm`}
                    pt={{root: {style: {borderRight: "none"}}}}
                    ref={addressInputRef}
                    id="location-address"
                    label="Address"
                    onFocus={() => {
                      setLocationBlur(false);
                    }}
                    onBlur={() => {
                      setLocationBlur(true);
                    }}
                  />
                  <Button type="button" icon="pi pi-times-circle" onClick={clearAddressInput} pt={ {root: { style: { background: "transparent", color: "black", border: "rgb(209, 213, 219) 1px solid", borderLeft: "none"}}}} />
                </div>
                {errors?.locationAddress?.message && <StyledErrorMsg>{errors?.locationAddress?.message}</StyledErrorMsg>}
              </div>
            </>
          )}
        />
        <div className="flex flex-col gap-y-5 w-fit max-w-full">
          {initialValues?.lawnSize != null && (
          <Controller
            control={control}
            name={"lawnSize"}
            rules={{
              required: "Lawn Size is required",
            }}
            render={({ field }) => (
              <div className="flex w-full flex-col gap-y-1.5">
              <StyledLabel>Lawn Size</StyledLabel>
                  <InputText
                    {...field}
                    disabled={initialValues?.lawnSize != null}
                    options={lawnSizeOptions}
                    onChange={(e) => { setValue("lawnSize", e.value) }}
                    value={`${ formatSqft(initialValues?.lawnSize)} sqft`}
                    className="w-full"
                  />
                  {errors?.lawnSize && (
                  <StyledErrorMsg>{errors?.lawnSize?.message}</StyledErrorMsg>
                )}
                {errors?.locationAddress?.message && <StyledErrorMsg>{errors?.locationAddress?.message}</StyledErrorMsg>}
              </div>
            )}
          />
          ) || (
            <Controller
            control={control}
            name={"lawnSize"}
            rules={{
              required: "Lawn Size is required",
            }}
            render={({ field }) => (
              <div className="flex flex-col w-full gap-y-1.5">
                <StyledLabel>Lawn Size</StyledLabel>
                <Dropdown
                  {...field}
                  options={lawnSizeOptions}
                  onChange={(e) => { setValue("lawnSize", e.value) }}
                  value={getValues().lawnSize === initialValues?.lawnSize ? lawnSizeOptions.find(option => option.code === initialValues?.lawnSize?.code) : getValues().lawnSize}
                  optionLabel="size"
                  placeholder="Select your lawn size"
                  className="w-full"
                />
                {errors?.lawnSize && (
                  <StyledErrorMsg>{errors?.lawnSize?.message}</StyledErrorMsg>
                )}
              </div>
              )}
            />
          )}
          <Controller
            control={control}
            name={"houseSize"}
            rules={{
              required: "House Size is required",
            }}
            render={({ field }) => (
              <div className="flex flex-col w-full gap-y-1.5">
                <StyledLabel>House Size</StyledLabel>
                <Dropdown
                  {...field}
                  options={houseSizeOptions}
                  onChange={(e) => { setValue("houseSize", e.value) }}
                  value={getValues().houseSize === initialValues?.houseSize ? houseSizeOptions.find(option => option.code === initialValues?.houseSize?.code) : getValues().houseSize}
                  optionLabel="size"
                  placeholder="Select your house size"
                  className="w-full"
                />
                {errors?.houseSize && (
                  <StyledErrorMsg>{errors?.houseSize?.message}</StyledErrorMsg>
                )}
              </div>
            )}
          />
        </div>

        <div>
          <span className="text-xs">*AI can calculate the lawn size</span>
        </div>
        <div className="flex flex-col justify-center gap-y-2.5">
          <div className="flex flex-row justify-center">
            <Button
              disabled={ loadingContinue || disabledContinue }
              loading={ loadingContinue }
              label="Calculate Pricing"
              icon={"pi pi-arrow-right"}
              iconPos="right"
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default LocationConfirmForm;