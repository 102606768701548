import React from "react";
import Markdown from "react-markdown";
import CardBody from "components/CardBody";
import { Tooltip } from "primereact/tooltip";

const InfoPopup = ({ priceId, description, visible, setVisible }) => (
  <>
    <i
      className={"pi pi-exclamation-circle " + `onboard-icon-${priceId}`}
    />
    <Tooltip target={`.onboard-icon-${priceId}`} mouseTrack mouseTrackTop={15} event="both" position="bottom">
      <CardBody className="flex flex-col self-center products-tooltip">
        {description
          .split("\n")
          .map((item, index) => (
            <Markdown className="" key={index}>{item}</Markdown>
          ))}
      </CardBody>
    </Tooltip>
</>
);
export default InfoPopup;
