import React from "react";
import { Card } from "primereact/card";

const CardBody = ({ children, className }) => {
  return (
    <Card className={`${className}`}>
      <div className="flex flex-col justify-center w-full">{children}</div>
    </Card>
  );
};
export default CardBody;
