import React, { useState, useRef, useEffect, useContext } from "react";
import { OnboardContext } from "providers/OnboardContext";
import { APILoader } from "@googlemaps/extended-component-library/react";
import { Button } from "primereact/button";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import {Context} from "providers/Context";
import StyledLabel from "components/InputField/components/StyledLabel";
import StyledErrorMsg from "components/InputField/components/StyledErrorMsg";


const LocationForm = ({ onSubmit, address, setAddress }) => {

  const { openInfoSnackbar, openErrorSnackbar } = useContext(Context);
  const { saveOnboardData } = useContext(OnboardContext);
  const [locationBlur, setLocationBlur] = useState(false);
  const [disabledContinue, setDisabledContinue] = useState(true);
  const [loadingContinue, setLoadingContinue] = useState(false);
  const { handleSubmit, control, setValue, getValues, formState, formState: { errors }, } = useForm();

  // used for google autocomplete
  const addressInputRef = useRef();
  let autoCompleteRef = useRef();
  let suggestionRef = useRef();
  let tokenRef = useRef();
  let timerId = useRef();

  const options = {
    componentRestrictions: { country: "us" },
    fields: ["formatted_address", "place_id"],
  };

  const startVerifyingDelay = async () => {
    clearTimeout(timerId.current); // Clear the previous timer if it exists
    setLoadingContinue(true);
    setDisabledContinue(true);

    timerId.current = setTimeout(async () => { // Set a new timer and store its ID
      try{

        if(addressInputRef.current.value.length == 0){
          throw new Error("Address field is empty")
        }

        let request = {
          input: addressInputRef.current.value,
          language: "en-US",
          region: "us",
          sessionToken: tokenRef.current,
        };

        let response = await suggestionRef.current.fetchAutocompleteSuggestions(request);
        if(response?.suggestions?.length > 0 && response?.suggestions?.length < 2){
          let place = response?.suggestions[0]?.placePrediction?.toPlace();
          let responseAddress = (await place?.fetchFields({
            fields: ["formattedAddress"],
          })).place.Eg.formattedAddress;

          if(responseAddress){
            responseAddress = responseAddress.endsWith(", USA") ? responseAddress : responseAddress + ", USA";
            addressInputRef.current.value = responseAddress;
            handleAddressInput(responseAddress);
            openInfoSnackbar("Address auto-filled");
          }else{
            throw new Error("No address found");
          }
        }else if(!response?.suggestions || response?.suggestions?.length === 0){
          throw new Error("No address found");
        }else if(response?.suggestions?.length > 1){
          throw new Error("Multiple Addresses found, please use dropdown selector");
        }else{
          throw new Error("An unknown error has occured");
        }
        
      }catch(e){
        setAddress(null);
        setValue("locationAddress", "");
        if(e.message.includes("error")){
          openErrorSnackbar(e.message);
        }else{
          openInfoSnackbar(e.message);
        }
      }finally{
        setLoadingContinue(false);
      }
    }, 2000);
  }

  const handleAddressInput = (address) => {
    if (address) {
      // eslint-disable-next-line
      const [street, city, stateWithZip, country] = address?.split(",");
      const [state, zipCode] = stateWithZip?.trimStart()?.split(" ");
      // Zipcode sometimes comes up empty
      if (zipCode !== undefined) {
        const split_address = {
          city: city?.trimStart(),
          state,
          street_address: street?.trimStart(),
          postal_code: zipCode,
        };

        saveOnboardData({"ora": address});
        saveOnboardData({"oa": split_address});

        setAddress({
          full_address: address,
          split_address: {
            city: city?.trimStart(),
            state,
            street_address: street?.trimStart(),
            postal_code: zipCode,
          },
        });
        setValue("locationAddress", address);
        setDisabledContinue(false);
      }
    }
  };

  const clearAddressInput = () => {
    if(addressInputRef){
      addressInputRef.current.value = "";
      setValue("locationAddress", address);
      setAddress({});
    }
  }
  
  const setUpAutoComplete = async () => {
    const { Autocomplete } = await APILoader.importLibrary("places");
    const { AutocompleteSessionToken, AutocompleteSuggestion } = await APILoader.importLibrary("places");
    tokenRef.current = new AutocompleteSessionToken();
    suggestionRef.current = AutocompleteSuggestion;
    autoCompleteRef = new Autocomplete(addressInputRef?.current, options);
    autoCompleteRef.addListener("place_changed", async () => {
      clearTimeout(timerId.current);
      const result = await autoCompleteRef.getPlace();
      if (result?.place_id) {
        handleAddressInput(result?.formatted_address);
        setLoadingContinue(false);
      } else {
        setAddress(null);
        setValue("locationAddress", "");
      }
    });
  };

  useEffect(() => {

    setUpAutoComplete();
    // eslint-disable-next-line
  }, []);

  return (
    <form id="confirm-location-form" onSubmit={handleSubmit((formData) => {
      onSubmit({
        ...formData,
        ...address,
      });
    })} className="flex flex-col items-center">
      <APILoader apiKey={process.env.REACT_APP_MAPS_API_KEY} />
      <div className="flex flex-col gap-y-5 items-start w-full">
        <Controller
          control={control}
          name={"locationAddress"}
          rules={{
            required: "Valid address is required",
          }}
          render={({ field: { name } }) => (
            <>
              <div className="flex w-full flex-col gap-y-1.5">
                <StyledLabel>Address</StyledLabel>
                <div className={`p-inputgroup flex-1`}>
                  <InputText
                    name={name}
                    placeholder=""
                    aria-label="Address Details"
                    type="text"
                    className={`p-inputtext-sm`}
                    pt={{root: {style: {borderRight: "none"}}}}
                    ref={addressInputRef}
                    id="location-address"
                    label="Address"
                    onChange={() => {
                      startVerifyingDelay();
                    }}
                    onFocus={() => {
                      setLocationBlur(false);
                    }}
                    onBlur={() => {
                      setLocationBlur(true);
                    }}
                  />
                  <Button type="button" icon="pi pi-times-circle" onClick={clearAddressInput} pt={ {root: { style: { background: "transparent", color: "black", border: "rgb(209, 213, 219) 1px solid", borderLeft: "none"}}}} />
                </div>
                {errors?.locationAddress?.message && <StyledErrorMsg>{errors?.locationAddress?.message}</StyledErrorMsg>}
              </div>
            </>
          )}
        />

        <div>
          <span className="text-xs">*AI can calculate the lawn size</span>
        </div>
        <div className="flex flex-col justify-center gap-y-2.5">
          <div className="flex flex-row justify-center">
            <Button
              disabled={disabledContinue || (addressInputRef.current && addressInputRef.current.value.length == 0)}
              loading={loadingContinue}
              label="Continue"
              icon={"pi pi-arrow-right"}
              iconPos="right"
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default LocationForm;