import React, { useContext, useEffect } from "react";
import { OnboardContext } from "providers/OnboardContext";
import CardBody from "components/CardBody";
import Header from "components/Header";
import OnboardConfirmSignUpForm from "./components/OnboardConfirmSignUpForm";

const OnboardConfirmSignUp = () => {
  const { setFormStep } = useContext(OnboardContext);

  useEffect(() => {
    setFormStep(4);
  }, []);
  return (
    <div className="flex flex-col gap-y-7 w-full items-center">
      <CardBody className={"max-w-[100vw] min-w-[72vw]"}>
        <div className="flex flex-col gap-y-5 justify-center">
          <div className="flex flex-col self-center w-4/5">
            <Header $size="h3" $weight="600">
              Confirm your account
            </Header>
          </div>
          <Header $size="h5" $weight="500" $color="#4B5563">
            A confirmation code was sent to your email.
          </Header>
          <OnboardConfirmSignUpForm />
        </div>
      </CardBody>
    </div>
  );
};

export default OnboardConfirmSignUp;
