import React, { useContext } from "react";
import { OnboardContext } from "providers/OnboardContext";
import { Steps } from "primereact/steps";
import NavLogo from "components/NavLogo";
import AppleBusinessChat from "components/Nav/components/AppleBusinessChat";

const OnboardNav = () => {
  const { formStep } = useContext(OnboardContext);

  const items = [
    { label: "Package" },
    { label: "Info" },
    { label: "Payment" },
    { label: "Account" },
  ];

  return (
    <>
      <div className="display-mobile px-[1rem] py-[10px]">
        <div className="flex flex-1 flex-row mb-[10px] justify-between">
          <NavLogo />
          <AppleBusinessChat />
        </div>
        <Steps model={items} activeIndex={formStep - 1} />
      </div>
      <div className="hide-mobile flex flex-row py-[8px] px-[1rem]">
        <div className="w-1/3 flex items-center justify-start">
          <NavLogo />
        </div>
        <div className="w-1/3">
          <Steps model={items} activeIndex={formStep - 1} />
        </div>
        <div className="flex flex-1 flex-row items-center justify-center min-h-[100%]">
          <AppleBusinessChat />
        </div>
      </div>
    </>
  );
};

export default OnboardNav;
