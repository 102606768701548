import React from "react";
import Header from "components/Header";
import InfoPopup from "../InfoPopup";

const OptionItem = ({
  item,
  formatCurrency,
  visible,
  setVisible,
  displayInfoIcon,
}) => (
  <div className="flex flex-col w-full">
    <div className="w-9/10 flex flex-row items-start justify-between md:justify-start md:gap-x-2.5">
      <label htmlFor={item?.price_id} className="ml-2">
        <Header $size="h6" $weight={700} $align="left">
          {item?.product_name}
        </Header>
      </label>
      {item?.popup_description && displayInfoIcon && (
        <InfoPopup
          priceId={item?.price_id}
          description={item?.popup_description}
          visible={visible}
          setVisible={setVisible}
        />
      )}
    </div>
    <div className="flex flex-col ml-2">
      <span className="package-card-subtext font-light">
        {`Adds $${formatCurrency(item?.price / 100)} monthly ($${formatCurrency(
          (item?.price / 100) * 12
        )} annually)`}
      </span>
      <span className="package-card-subtext font-light">
        {`You save $${formatCurrency(
          (item?.savings_amount / 100) * 12
        )} per year`}
      </span>
      <span className="italic text-xs">{item?.extra_description}</span>
    </div>
  </div>
);
export default OptionItem;
