import React from 'react'

export const Switch = ({ condition, children }) => {
  if (!children) {
    return null
  }

  const arrayOfChildren = Array.isArray(children) ? children : [children]
  const cases = arrayOfChildren.filter((child) => child.props.when === condition)
  const defaultCases = arrayOfChildren.filter((child) => !child.props.when)
  
  if (defaultCases.length > 1) {
    throw new Error('Only one <Switch.Default> is allowed')
  }

  const defaultCase = defaultCases[0]

  return cases.length > 0 ? <>{cases}</> : <>{defaultCase}</>
}

Switch.Case = ({ children }) => {
  return <>{children}</>
}

Switch.Default = ({ children }) => {
  return <>{children}</>
}

export default Switch