import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import InputField from "components/InputField";
import { Button } from "primereact/button";
import useApi from "api/useApi";

//TODO: When or if we allow phone update, replace with phone field in onboard
const AccountForm = ({ user }) => {
  const { updateProfileApi } = useApi();

  const updateProfile = async (data) => {
    await updateProfileApi({
      first_name: data?.first_name,
      last_name: data?.last_name,
    });
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { first_name: "", last_name: "", email: "", phone: "" },
  });
  useEffect(() => {
    reset({
      first_name: user?.first_name,
      last_name: user?.last_name,
      email: user?.email,
      phone: user?.phone,
    });
  }, [user, reset]);
  return (
    <form
      id="account-form"
      className="flex flex-col items-center"
      onSubmit={handleSubmit((formData) => {
        updateProfile(formData);
      })}
    >
      <div className="flex flex-col gap-y-5 full-width-xs w-full mx-5 ">
        <InputField
          register={register}
          registerName={"first_name"}
          label="First Name"
          placeholder=""
          required={"First name is required"}
          pattern={{
            value: /[^\s]+/,
            message: "Cannot be only spaces.",
          }}
          errorMessage={errors?.first_name?.message}
        />
        <InputField
          register={register}
          registerName={"last_name"}
          label="Last Name"
          placeholder=""
          required={"Last name is required"}
          pattern={{
            value: /[^\s]+/,
            message: "Cannot be only spaces.",
          }}
          errorMessage={errors?.last_name?.message}
        />
        <InputField
          register={register}
          registerName={"email"}
          type={"email"}
          label="Email Address"
          placeholder=""
          pattern={{ value: /[^\s]+/, message: "Cannot be only spaces." }}
          errorMessage={errors?.email?.message}
          disabled
        />
        <InputField
          register={register}
          registerName={"phone"}
          label="Phone Number"
          placeholder=""
          errorMessage={errors?.phone?.message}
          disabled
        />
        <div className="flex flex-row justify-center w-full">
          <Button type="submit" label="Save Changes" rounded />
        </div>
      </div>
    </form>
  );
};
export default AccountForm;
