import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useApi from "api/useApi";
import { OnboardContext } from "providers/OnboardContext";
import NameForm from "./components/NameForm";
import Header from "components/Header";
import CardBody from "components/CardBody";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

const OnboardName = () => {
  const { createNameOnboard, getOnboardData, createContactUs } = useApi();
  const { setFormStep } = useContext(OnboardContext);
  const [dialogVisible, setDialogVisible] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (formData) => {
    const nameRes = await createNameOnboard(formData);
    if (nameRes?.status === 200) {
      const house_size = getOnboardData("ohs");

      if (house_size && house_size.code === "house_6501_plus") {
        const email = getOnboardData("oe");
        const phone = getOnboardData("oph");
        const hubspot_id = getOnboardData("oh");

        const contactUsRes = await createContactUs({
          ...formData,
          email,
          phone,
          hubspot_id,
          comments:
            "Potential client has a house larger than 6501sqft. Client requires a callback for prices and services available.",
        });
        contactUsRes?.status === 200 && setDialogVisible(true);
      } else {
        navigate(`/onboard/payment`);
      }
    }
  };

  useEffect(() => {
    setFormStep(2);
  }, []);

  return (
    <CardBody className={"min-w-[72vw] max-w-[100vw]"}>
      <div className="flex flex-col gap-y-5 mb-2">
        <Header $size="h3" $weight="600">
          Who's account is this?
        </Header>
        <NameForm onSubmit={handleSubmit} />
        <Dialog
          visible={dialogVisible}
          header=""
          style={{ width: "84vw", maxWidth: "30rem" }}
          onHide={() => {
            if (!dialogVisible) return;
            setDialogVisible(false);
            navigate("/onboard");
          }}
          content={({ hide }) => (
            <div className="flex flex-col justify-center items-center gap-4 p-8 bg-[#ffffff] rounded-lg">
              <Header $size="h4" $weight="300">
                We will be in contact with you soon regarding your custom
                package.
              </Header>
              <Button
                rounded
                style={{ maxWidth: "fit-content" }}
                label="Continue"
                onClick={(e) => {
                  hide(e);
                  navigate("/onboard");
                }}
              />
            </div>
          )}
        />
      </div>
    </CardBody>
  );
};
export default OnboardName;
