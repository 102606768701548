import React from "react";
import CardBody from "components/CardBody";
import Header from "components/Header";

const Unsubscribed = () => {
  return (
    <CardBody className={"max-w-[100vw]"}>
      <Header $size="h4" $weight="500">
        You have been unsubscribed
      </Header>
    </CardBody>
  );
};

export default Unsubscribed;
