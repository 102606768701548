import { useRef, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Context } from "providers/Context";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import NavLogo from "components/NavLogo";
import useAuth from "api/useAuth";
import AppleBusinessChat from "./components/AppleBusinessChat";
import { Dropdown } from "primereact/dropdown";
import { TabMenu } from "primereact/tabmenu";

// stylings for onboard nav is under onboardNav
const Nav = () => {
  const { isAuthorized, isOnboarded } = useContext(Context);

  const menuRight = useRef(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { handleSignOut } = useAuth();

  /** 
   * The following may be used again in the future but for now it is commented out
  const isProd = process.env.REACT_APP_ENVIRONMENT === "production";
  const publicNavItems = [
    {
      label: "Home",
      url: isProd ? "https://oneoshome.com/" : "https://dev.oneoshome.com/",
    },
    {
      label: "Memberships",
      command: () => {
        navigate("/onboard");
      },
    },
    {
      label: "Services",
      url: isProd
        ? "https://oneoshome.com/services"
        : "https://dev.oneoshome.com/services",
    },
    {
      label: "About Us",
      url: isProd
        ? "https://oneoshome.com/about"
        : "https://dev.oneoshome.com/about",
    },
  ];
  */
  const dashNavItems = [
    {
      label: "Sign Out",
      command: async () => {
        await handleSignOut();
      },
    },
  ];

  const secondaryDashNavItems = [
    {
      label: "Dashboard",
      command: () => {
        navigate("/dashboard");
      },
    },
    {
      label: "My Home",
      command: () => {
        navigate("/dashboard/home");
      },
    },
    {
      label: "My Documents",
      command: () => {
        navigate("/dashboard/documents");
      },
    },
  ];

  const isDash = /\/dashboard/.test(pathname);

  return (
    <div className="flex flex-col bg-[#FFF] h-fit py-4 gap-4">
      <div className="flex flex-row flex-wrap h-full justify-between gap-3 items-center max-[825px]:flex-col">
        <div className="flex flex-1 flex-row items-center justify-between gap-3 h-[2.5rem] w-full pl-3 pb-5 min-[825px]:hidden">
          <NavLogo />
          {isAuthorized && (
            <div className="flex flex-row h-full min-[450px]:w-full min-w-fit w-fit justify-end mr-4 gap-2">
              <Menu
                model={dashNavItems}
                popup
                ref={menuRight}
                id="popup_menu_right"
                popupAlignment="right"
              />
              <AppleBusinessChat />
              <Button
                icon="pi pi-cog"
                onClick={(event) => menuRight.current.toggle(event)}
                rounded
                id="settings-button"
                className="nav-button"
                aria-controls="popup_menu_right"
                aria-haspopup
              />
            </div>
          )}
        </div>

        <div className="flex flex-1 flex-row items-center justify-between h-fit min-w-fit pl-3 max-[825px]:hidden">
          <NavLogo />
        </div>
        <div
          className={`flex flex-row flex-1 h-[2.5rem] max-w-full max-[825px]:order-3 justify-center items-center hidden`}
        >
          {/*${isAuthorized ? "" : "hidden"}`*/}
          {isAuthorized && (
            <div className="flex flex-row items-center justify-center gap-3">
              {/* <IconField iconPosition="left">
                <InputIcon className="pi pi-home"> </InputIcon>
                <Dropdown value={{ code: "address", name: "Address" }} disabled options={[{ code: "address", name: "Address" }]} optionLabel="name" 
                  placeholder="Select a City" className="w-full md:w-14rem address-dropdown" icon="pi pi-home" />
              </IconField> */}
            </div>
          )}
        </div>
        <div
          className={`flex flex-row flex-1 h-[2.5rem] max-[825px]:hidden ${
            isAuthorized ? "" : "hidden"
          }`}
        >
          {isAuthorized && (
            <div className="flex flex-row h-full min-[450px]:w-full min-w-fit w-fit justify-end mr-4 gap-2">
              <Menu
                model={dashNavItems}
                popup
                ref={menuRight}
                id="popup_menu_right"
                popupAlignment="right"
              />
              <AppleBusinessChat />
              <Button
                icon="pi pi-cog"
                onClick={(event) => menuRight.current.toggle(event)}
                rounded
                id="settings-button"
                className="nav-button"
                aria-controls="popup_menu_right"
                aria-haspopup
              />
            </div>
          )}
        </div>
      </div>
      {/*isAuthorized && (
        <div className="flex flex-row flex-1 h-[2.5rem] w-full nav-secondary-container justify-center items-center">
          <TabMenu model={secondaryDashNavItems} />
        </div>
      )*/}
    </div>
  );
};

export default Nav;
