import React, { useEffect } from "react";
import InputField from "components/InputField";
import { Button } from "primereact/button";
import useAuth from "api/useAuth";
import { useForm } from "react-hook-form";
import PasswordGuide from "components/PasswordGuide";

const ConfirmNewPasswordForm = () => {
  const { handleConfirmNewPassword } = useAuth();

  const initialValues = {
    newPassword: "",
    confirmPassword: "",
  };

  const {
    register,
    watch,
    setError,
    clearErrors,
    formState: { errors, isSubmitSuccessful },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: { ...initialValues },
  });

  const [newPassword, confirmPassword] = watch([
    "newPassword",
    "confirmPassword",
  ]);

  useEffect(() => {
    if (
      !(confirmPassword?.length === 0) &&
      !(newPassword === confirmPassword)
    ) {
      setError("confirmPassword", {
        type: "custom",
        message: "Passwords do not match.",
      });
    }

    if (newPassword === confirmPassword) {
      clearErrors("confirmPassword");
    }
  }, [newPassword, confirmPassword, setError, clearErrors]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({ newPassword: "", confirmPassword: "" });
    }
  }, [isSubmitSuccessful, reset]);

  return (
    <form id="login-form" className="flex flex-col items-center">
      <div className="flex flex-col gap-y-5 full-width-xs w-[250px]">
        <InputField
          type="password"
          label="New Password"
          placeholder=""
          register={register}
          registerName="newPassword"
          required={"Password is required"}
          errorMessage={errors?.newPassword?.message}
          maxLength={{ value: 99, message: "Maximum 99 characters" }}
          minLength={{ value: 8, message: "Minimum 8 characters" }}
          pattern={{
            value:
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,99}$/,
            message:
              "Minimum 8 characters, mix of upper and lower case, a special character: #,$,@,!,%,&,*,? and at least one number.",
          }}
        />

        <InputField
          type="password"
          label="Confirm Password"
          placeholder=""
          register={register}
          registerName="confirmPassword"
          required={"Confirm password is required"}
          errorMessage={errors?.confirmPassword?.message}
        />
        <PasswordGuide />
        <div className="flex flex-row justify-center w-full">
          <Button
            type="text"
            label="Reset Password"
            rounded
            onClick={handleSubmit(async (data) => {
              await handleConfirmNewPassword(data);
            })}
          />
        </div>
      </div>
    </form>
  );
};

export default ConfirmNewPasswordForm;
