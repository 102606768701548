import React, { useContext, useEffect } from "react";
import { OnboardContext } from "providers/OnboardContext";
import useApi from "api/useApi";
import ContactUserForm from "./components/ContactUserForm";
import Header from "components/Header";
import CardBody from "components/CardBody";

const OnboardContact = () => {
  const { createContactOnboard } = useApi();
  const { setFormStep } = useContext(OnboardContext);
  const handleSubmit = async ({ email, phone }) => {
    await createContactOnboard({ email, phone });
  };

  useEffect(() => {
    setFormStep(2);
    // eslint-disable-next-line
  }, []);

  return (
    <CardBody className={"min-w-[72vw] max-w-[100vw]"}>
      <div className="flex flex-col gap-y-5">
        <Header $size="h3" $weight="600">
          How do we get in touch?{" "}
        </Header>
        <Header $size="h5" $weight="500" $color="#4B5563">
          If you're an existing customer, be sure to use the same email or phone
          number.
        </Header>
        <ContactUserForm onSubmit={handleSubmit} />
      </div>
    </CardBody>
  );
};
export default OnboardContact;
