import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import useApi from "api/useApi";

const ContactOauthOnboard = () => {
  const { createContactUsMember } = useApi();
  const [visible, setVisible] = useState(true);

  return (
    <Dialog
      visible={visible}
      style={{ width: "20rem" }}
      onHide={() => {
        if (!visible) return;
        setVisible(false);
      }}
      closable={false}
      header=""
      content={
        <div className="flex flex-col items-center justify-center h-full max-h-full p-8 bg-[#ffffff] rounded-lg">
          <h1 className="text-2xl font-bold text-center">
            You're not onboarded yet
          </h1>
          <br />
          <p className="text-xl text-center">
            An agent will be in contact with you soon via email to complete the
            onboarding process. In the meantime, the dashboard may appear empty.
          </p>
          <br />
          <Button
            label="Close"
            icon="pi pi-times"
            iconPos="right"
            onClick={() => {
              createContactUsMember({
                comments: "Oauth user needs Onboarding",
              });
              if (!visible) return;
              setVisible(false);
            }}
          />
        </div>
      }
    />
  );
};

export default ContactOauthOnboard;
