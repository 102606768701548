import { Routes, Route, Navigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { Context } from "./providers/Context";
import { fetchAuthSession } from "aws-amplify/auth";
import Loader from "./components/Loader";
import SnackbarComponent from "./components/SnackbarComponent";
import ScrollToTop from "./components/ScrollToTop";
import Root from "components/Root";
import SignIn from "./pages/SignIn";
import Calendar from "./pages/Calendar";
import Payment from "./pages/Payment";
import Account from "./pages/Account";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Unsubscribed from "./pages/Unsubscribed";
import Membership from "./pages/Membership";
import Onboard from "pages/Onboard";
import OnboardConfirmSignUp from "pages/OnboardConfirmSignUp";
import OnboardCustomPackage from "pages/OnboardCustomPackage";
import OnboardComplete from "pages/OnboardComplete";
import OnboardLocation from "pages/OnboardLocation";
import OnboardContact from "pages/OnboardContact";
import OnboardPayment from "pages/OnboardPayment";
import SuccessContact from "pages/SuccessContact";
import ConfirmSignUp from "pages/ConfirmSignUp";
import OnboardName from "pages/OnboardName";
import ContactUs from "pages/ContactUs";

import useApi from "api/useApi";
import { Button } from "primereact/button";
import ContactOauthOnboard from "components/ContactOauthOnboard";

const ProtectedRoute = ({ children }) => {

  const { isAuthorized, isOnboarded } = useContext(Context);
  const [visible, setVisible] = useState(true);

  if (!isAuthorized) {
    return <Navigate to="/" />;
  }
  return children;
};

const Routing = () => {
  const { isLoading, setIsAuthorized, isAuthorized, isOnboarded, setIsOnboarded, setCurrentSession } = useContext(Context);
  const { getUserInfoApi } = useApi();
  const [firstLoading, setFirstLoading] = useState(false);

  // used to keep auth when refreshed
  useEffect(() => {
    // move to context did not work well when using prev ls set up
    const fun = async () => {
      setFirstLoading(false);
      const session = await fetchAuthSession();
      const token = session?.tokens?.idToken.toString();
      if (token) {
        await setIsAuthorized(true);
        const userInfo = await getUserInfoApi();
        if(userInfo?.status === "incomplete"){
          setIsOnboarded(false);
        }
      }

      setCurrentSession(session);
      setFirstLoading(true);
    };
    fun();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {/* Show a special loader when calculating address otherwise spinner */}
      <Loader open={isLoading} />
      <SnackbarComponent />
      <ScrollToTop />
      {firstLoading && (
        <Routes>
          {/* Auth routes */}
          <Route path="/dashboard" element={<Root />}>
            <Route index element={<Navigate to="profile" replace />} />
            <Route
              path="profile"
              element={
                <ProtectedRoute>
                  {isAuthorized && isOnboarded === false && (
                    <ContactOauthOnboard/>
                  )}
                  <Account />
                </ProtectedRoute>
              }
            />
            {/*<Route
              path="scheduling"
              element={
                <ProtectedRoute>
                  <Calendar />
                </ProtectedRoute>
              }
            />
            <Route
              path="payment"
              element={
                <ProtectedRoute>
                  <Payment />
                </ProtectedRoute>
              }
            />*/}
            <Route
              path="membership"
              element={
                <ProtectedRoute>
                  {isAuthorized && isOnboarded === false && (
                    <ContactOauthOnboard/>
                  )}
                  <Membership />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* Onboarding routes*/}
          <Route
            path="/onboard"
            element={
              isAuthorized ? <Navigate replace to="/dashboard" /> : <Onboard />
            }
          >
            <Route index element={<OnboardLocation />} />
            <Route path="custom-package" element={<OnboardCustomPackage />} />
            <Route path="contact" element={<OnboardContact />} />
            <Route path="information" element={<OnboardName />} />
            <Route path="payment" element={<OnboardPayment />} />
            <Route path="complete" element={<OnboardComplete />} />
            <Route path="confirm" element={<OnboardConfirmSignUp />} />
            <Route path="custom-package" element={<OnboardCustomPackage />} />
          </Route>

          {/* Other routes */}
          <Route element={<Root />}>
            <Route
              path="/"
              element={
                isAuthorized ? <Navigate replace to="/dashboard" /> : <SignIn />
              }
            />
            <Route path="confirm" element={<ConfirmSignUp />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="unsubscribed" element={<Unsubscribed />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="success-contact" element={<SuccessContact />} />
            <Route path="/*" element={<Navigate replace to="/" />} />
          </Route>
        </Routes>
      )}
    </>
  );
};

export default Routing;
