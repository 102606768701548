import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import InputField from "components/InputField";
import { Button } from "primereact/button";
import useAuth from "api/useAuth";

const LoginForm = () => {
  const navigate = useNavigate();
  const { handleSignIn, handleSignInWithRedirect } = useAuth();

  const initialValues = {
    username: "",
    password: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { ...initialValues },
  });

  return (
    <form id="login-form" className="flex flex-col items-center">
      <div className="flex flex-col full-width-xs w-[250px]">
        <div className="flex flex-col gap-y-5 mb-5">
          <InputField
            register={register}
            registerName={"username"}
            type={"email"}
            label="Email Address"
            placeholder=""
            required={"Email is required"}
            pattern={{
              value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
              message: "Enter a valid email address",
            }}
            errorMessage={errors?.username?.message}
          />
          <InputField
            type="password"
            label="Password"
            placeholder=""
            register={register}
            registerName="password"
            required={"Password is required"}
            errorMessage={errors?.password?.message}
          />
        </div>
        <div>
          <div className="flex flex-row justify-center w-full">
            <Button
              onClick={handleSubmit(async (data) => {
                await handleSignIn(data);
              })}
              label="Sign In"
              className="w-full"
              rounded
            />
          </div>
          <div className="flex flex-row justify-center w-full">
            <Button
              type="text"
              label="Forgot Password"
              link
              onClick={(e) => {
                e.preventDefault();
                navigate("/forgot-password");
              }}
            />
          </div>
        </div>
        <div className="relative pt-5 w-full flex flex-col gap-y-3 justify-center items-center border-t-2 border-mainColor overflow-visible">
          <p className="absolute top-[-0.75rem] mx-auto px-2 pb-5 bg-altBackgroundColor">
            OR
          </p>
          <Button
            rounded
            type="button"
            name="googleSignUpButton"
            className="w-full"
            onClick={() => {
              handleSignInWithRedirect({ provider: "Google" });
            }}
            label="Continue with Google"
            icon="pi pi-google"
          ></Button>
        </div>

      </div>
    </form>
  );
};
export default LoginForm;
