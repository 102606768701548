import { InputNumber } from "primereact/inputnumber";
import Header from "components/Header";
import { Tooltip } from "primereact/tooltip";
import CardBody from "components/CardBody";
import Markdown from "react-markdown";


//Max value: surface wash 4, gutters 4, furnance 4
const SingleIncrement = ({
  setVal,
  val,
  label,
  subtext,
  max = 4,
  popup,
  priceId,
  visible,
  setVisible,
}) => {
  return (
    <div className={`flex flex-col mb-2`}>
      <div
        className={`flex flex-col mb-2 gap-x-2 items-start sm:flex-row sm:items-center`}
      >
        <Header $size="h6" $weight={700} $align="left">
          {label}
        </Header>
        {subtext && <span className="text-xs">{subtext}</span>}
      </div>
      <div className={`flex flex-row items-start gap-x-2 mb-2`}>
        <InputNumber
          className="package-inputnumber"
          value={val}
          onValueChange={(e) => {
            setVal(e.value);
          }}
          showButtons
          buttonLayout="horizontal"
          step={1}
          min={0}
          max={max}
          decrementButtonClassName="p-button-plain"
          incrementButtonClassName="p-button-plain"
          incrementButtonIcon="pi pi-plus"
          decrementButtonIcon="pi pi-minus"
        />

        {popup && (
          <>
            <i
              className={"pi pi-exclamation-circle " + `onboard-icon-${priceId}`}
            />
            <Tooltip target={`.onboard-icon-${priceId}`} mouseTrack mouseTrackTop={15} event="both" position="bottom">
              <CardBody className="flex flex-col self-center products-tooltip">
                {popup
                  .split("\n")
                  .map((item, index) => (
                  <Markdown className="" key={index}>{item}</Markdown>
                  ))}
              </CardBody>
            </Tooltip>
          </>
        )}
      </div>
    </div>
  );
};

export default SingleIncrement;
