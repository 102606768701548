import React, { forwardRef } from "react";
import { InputText } from "primereact/inputtext";
import StyledLabel from "components/InputField/components/StyledLabel";
import StyledErrorMsg from "components/InputField/components/StyledErrorMsg";
import { InputTextarea } from "primereact/inputtextarea";

const UnregisteredField = forwardRef(
  (
    { label, errorMessage, placeholder, disabled, textarea, type, className, ...rest },
    ref
  ) => (
    <div className="flex flex-col w-full gap-y-1.5">
      <StyledLabel>{label}</StyledLabel>
      {!textarea ? (
        <InputText
          placeholder={placeholder}
          type={type ? type : "text"}
          className={`p-inputtext-sm ${className ?? ""}`}
          disabled={disabled}
          ref={ref}
          {...rest}
        />
      ) : (
        <InputTextarea
          ref={ref}
          placeholder={placeholder}
          className={className ?? ""}
          {...rest}
        ></InputTextarea>
      )}
      {errorMessage && <StyledErrorMsg>{errorMessage}</StyledErrorMsg>}
    </div>
  )
);

export default UnregisteredField;
