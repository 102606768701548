import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import InputField from "components/InputField";
import { Button } from "primereact/button";
import PasswordGuide from "components/PasswordGuide";
import useAuth from "api/useAuth";
const ResetPasswordForm = () => {
  const initialValues = {
    username: "",
    newPassword: "",
    confirmNewPassword: "",
    confirmationCode: "",
  };

  const { handleConfirmResetPassword } = useAuth();
  const {
    register,
    watch,
    setError,
    clearErrors,
    formState: { errors, isSubmitSuccessful },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: { ...initialValues },
  });

  const [newPassword, confirmNewPassword] = watch([
    "newPassword",
    "confirmNewPassword",
  ]);

  useEffect(() => {
    if (
      !(confirmNewPassword?.length === 0) &&
      !(newPassword === confirmNewPassword)
    ) {
      setError("confirmNewPassword", {
        type: "custom",
        message: "Passwords do not match.",
      });
    }
    if (newPassword === confirmNewPassword) {
      clearErrors("confirmNewPassword");
    }
  }, [newPassword, confirmNewPassword, setError, clearErrors]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({ ...initialValues });
    }
    // eslint-disable-next-line
  }, [isSubmitSuccessful, reset]);

  const resetPassword = async ({ username, newPassword, confirmationCode }) => {
    await handleConfirmResetPassword({
      username,
      newPassword,
      confirmationCode,
    });
  };
  return (
    <form
      id="reset-password-form"
      className="flex flex-col items-center"
      onSubmit={handleSubmit((formData) => {
        resetPassword(formData);
      })}
    >
      <div className="flex flex-col gap-y-5 full-width-xs w-[250px]">
        <InputField
          register={register}
          registerName={"username"}
          type={"email"}
          label="Email Address"
          placeholder=""
          required={"Email is required"}
          pattern={{
            value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
            message: "Enter a valid email address",
          }}
          errorMessage={errors?.username?.message}
        />
        <PasswordGuide />
        <InputField
          type="password"
          label="New Password"
          placeholder=""
          register={register}
          registerName="newPassword"
          required={"Password is required"}
          errorMessage={errors?.newPassword?.message}
          maxLength={{ value: 99, message: "Maximum 99 characters" }}
          minLength={{ value: 8, message: "Minimum 8 characters" }}
          pattern={{
            value:
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,99}$/,
            message:
              "Minimum 8 characters, mix of upper and lower case, a special character: #,$,@,!,%,&,*,? and at least one number.",
          }}
        />

        <InputField
          type="password"
          label="Confirm New Password"
          placeholder=""
          register={register}
          registerName="confirmNewPassword"
          required={"Confirm password is required"}
          errorMessage={errors?.confirmNewPassword?.message}
        />

        <InputField
          type="text"
          label="Reset Code"
          placeholder=""
          register={register}
          registerName="confirmationCode"
          required={"Reset code is required"}
          errorMessage={errors?.confirmationCode?.message}
        />
        <div className="flex flex-row justify-center w-full">
          <Button type="submit" label="Reset Password" rounded />
        </div>
      </div>
    </form>
  );
};

export default ResetPasswordForm;
