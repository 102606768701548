import React, { useState, useEffect, useContext } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { OnboardContext } from "providers/OnboardContext";
import useApi from "api/useApi";
import Header from "components/Header";
import CardBody from "components/CardBody";

const stripePromise = await loadStripe(process.env.REACT_APP_STRIPE_SECRET_KEY);

const OnboardPayment = () => {
  const { setFormStep } = useContext(OnboardContext);
  const { createPaymentOnboard, confirmCheckout, getOnboardData } = useApi();
  const navigate = useNavigate();

  const [clientSecret, setClientSecret] = useState("");

  const handleComplete = async () => {
    const res = await confirmCheckout();

    if (res?.status === 200) {
      setClientSecret("");
      navigate("/onboard/complete", { replace: true });
    }
  };
  const fetchCS = async () => {
    const res = await createPaymentOnboard();
    setClientSecret(res?.data?.checkout_session_key);
  };
  useEffect(() => {
    setFormStep(3);
    const house_size = getOnboardData("ohs");
    // Edge case: Client goes to this page directly when house size is larger than 6500+
    if (house_size && house_size?.code === "house_6501_plus") {
      navigate("/onboard", { replace: true });
    } else {
      fetchCS(); // eslint-disable-next-line
    }
  }, []);

  return (
    <CardBody className={"min-w-[72vw] max-w-[100vw]"}>
      <div className="flex flex-col gap-y-5 mb-2">
        <Header $size="h3" $weight="600">
          Payment details
        </Header>
        <div id="payment">
          {clientSecret ? (
            <EmbeddedCheckoutProvider
              stripe={stripePromise}
              options={{ clientSecret, onComplete: handleComplete }}
            >
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          ) : (
            <div>
              This may take a few moments. Please don't refresh the screen while
              Stripe is loading.
            </div>
          )}
        </div>
      </div>
    </CardBody>
  );
};

export default OnboardPayment;
