import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import InputField from "components/InputField";
import { Button } from "primereact/button";
import useAuth from "api/useAuth";
import PasswordGuide from "components/PasswordGuide";

const ChangePasswordForm = () => {
  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };
  const { handleUpdatePassword } = useAuth();
  const {
    register,
    watch,
    setError,
    clearErrors,
    formState: { errors, isSubmitSuccessful },
    handleSubmit,
    reset,
  } = useForm({ defaultValues: { ...initialValues } });

  const [newPassword, confirmNewPassword] = watch([
    "newPassword",
    "confirmNewPassword",
  ]);

  useEffect(() => {
    if (
      !(confirmNewPassword?.length === 0) &&
      !(newPassword === confirmNewPassword)
    ) {
      setError("confirmNewPassword", {
        type: "custom",
        message: "Passwords do not match.",
      });
    }
    if (newPassword === confirmNewPassword) {
      clearErrors("confirmNewPassword");
    }
  }, [newPassword, confirmNewPassword, setError, clearErrors]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({ newPassword: "", confirmNewPassword: "", oldPassword: "" });
    }
  }, [isSubmitSuccessful, reset]);
  const changePassword = async ({ newPassword, oldPassword }) => {
    await handleUpdatePassword({ newPassword, oldPassword });
  };
  return (
    <form
      id="change-password-form"
      className="flex flex-col items-center"
      onSubmit={handleSubmit((formData) => {
        changePassword(formData);
      })}
    >
      <div className="flex flex-col gap-y-5 full-width-xs w-full mx-5">
        <InputField
          type="password"
          label="Current Password"
          placeholder=""
          register={register}
          registerName="oldPassword"
          required={"Current password is required"}
          errorMessage={errors?.oldPassword?.message}
        />
        <InputField
          type="password"
          label="New Password"
          placeholder=""
          register={register}
          registerName="newPassword"
          required={"Password is required"}
          errorMessage={errors?.newPassword?.message}
          maxLength={{ value: 99, message: "Maximum 99 characters" }}
          minLength={{ value: 8, message: "Minimum 8 characters" }}
          pattern={{
            value:
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,99}$/,
            message:
              "Minimum 8 characters, mix of upper and lower case, a special character: #,$,@,!,%,&,*,? and at least one number.",
          }}
        />
        <PasswordGuide />
        <InputField
          type="password"
          label="Confirm New Password"
          placeholder=""
          register={register}
          registerName="confirmNewPassword"
          required={"Confirm password is required"}
          errorMessage={errors?.confirmNewPassword?.message}
        />
        <div className="flex flex-row justify-center w-full">
          <Button type="submit" label="Update Password" rounded />
        </div>
      </div>
    </form>
  );
};

export default ChangePasswordForm;
