import React from "react";
import CardBody from "components/CardBody";
import Header from "components/Header";
import ResetPasswordForm from "./ResetPasswordForm";

// Directed here through email for resetting password
const ResetPassword = () => {
  return (
    <div className="flex flex-col gap-y-7 w-full items-center">
      <CardBody className={"max-w-[100vw] min-w-[72vw]"}>
        <div className="flex flex-col gap-y-5 justify-center">
          <div className="flex flex-col self-center w-4/5">
            <Header $size="h3" $weight="600">
              Reset Password
            </Header>
          </div>
          <ResetPasswordForm />
        </div>
      </CardBody>
    </div>
  );
};

export default ResetPassword;
