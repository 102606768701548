import React, { useContext, useEffect, useState } from "react";
import { Context } from "providers/Context";
import useApi from "api/useApi";
import { OnboardContext } from "providers/OnboardContext";
import CreateAccountForm from "./components/CreateAccountForm";
import Header from "components/Header";
import CardBody from "components/CardBody";
import PasswordGuide from "components/PasswordGuide";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

const OnboardComplete = () => {
  const { getOnboardData } = useContext(OnboardContext);
  const { createAccountOnboard, createAgentAccountOnboard } = useApi();
  const { openInfoSnackbar, openErrorSnackbar } = useContext(Context);
  const { setFormStep } = useContext(OnboardContext);
  const [customerEmailInputValue, setCustomerEmailInputValue] = useState(getOnboardData("oe") ?? "");

  const handleSubmit = async (formData) => {
    await createAccountOnboard(formData);
  };

  const handleAgentSubmit = async (event) => {
    event.preventDefault();
    const res = await createAgentAccountOnboard(customerEmailInputValue);
    if (res.status !== 200) {
      openErrorSnackbar(res.message);
    }else{
      setCustomerEmailInputValue("");
      openInfoSnackbar("Email sent");
    }
  };

  useEffect(() => {
    setFormStep(4);
  }, []);
  
  return (
    <CardBody className={"min-w-[72vw] max-w-[100vw]"}>
      <div className="flex flex-col gap-y-5 mb-2 items-center">
        <Header $size="h3" $weight="600">
          Welcome to oneOS Home!
        </Header>
        <div className="flex flex-row self-center max-w-[560px]">
          {window.location.hostname !== "agent-dev.oneoshome.com" && window.location.hostname !== "agent.oneoshome.com" && process.env.REACT_APP_ENVIRONMENT !== "agent" ?
            <Header $size="h5" $weight="500" $color="#4B5563">
              Your membership has begun and the last step is to create your
              account. The oneOS team will be reaching out soon to schedule your
              first home services!
            </Header>
           :
            <Header $size="h5" $weight="500" $color="#4B5563">
              Please verify autofill or enter the email of the customer you are onboarding.<br/>
              (This will be used to send the customer a "create a password" link.)
            </Header>
          }
        </div>
        {window.location.hostname !== "agent-dev.oneoshome.com" && window.location.hostname !== "agent.oneoshome.com" && process.env.REACT_APP_ENVIRONMENT !== "agent" ?
          <>
            <PasswordGuide />
            <CreateAccountForm onSubmit={handleSubmit} />
          </>
        :
          <form className="flex flex-col gap-y-5 items-center" onSubmit={handleAgentSubmit}>
            <div className="flex flex-col full-width-xs w-[250px]">
              <label htmlFor="customerEmail">Customer Email</label>
              <InputText id="customerEmail" keyfilter="email" type="email" required value={customerEmailInputValue} onChange={(e) => setCustomerEmailInputValue(e.target.value)} />  
            </div>
            <Button type="submit" style={{ width: "250px", maxWidth: "100%" }} label="Send Email" rounded />
          </form>
        }
      </div>
    </CardBody>
  );
};
export default OnboardComplete;
