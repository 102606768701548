import React from "react";
import CardBody from "components/CardBody";
import Header from "components/Header";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";

const SuccessContact = () => {
  const navigate = useNavigate();
  return (
    <CardBody className="flex flex-col  gap-y-4 items-center justify-center">
      <Header $size="h4" $weight="500">
      Thank You For Contacting Us!
      </Header>
      <Header $size="h6" $weight="400">
      We have received your details and will contact you shortly.
      </Header>
      <div className="flex flex-row justify-center">
            <Button
              onClick={(e) => {
                e.preventDefault();
                window.location.replace(process.env.REACT_APP_ENVIRONMENT === "development" ? "https://dev.oneoshome.com/" : "https://oneoshome.com/");
              }}
              label="Return to Homepage"
              link
            />{" "}
          </div>
    </CardBody>
  );
};

export default SuccessContact;
