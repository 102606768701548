import styled from "styled-components";

const StyledInput = styled.label`
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  font-family: Raleway, sans-serif;
  color: #4b5563; // use var.. textColor
`;

export default StyledInput;
