import styled, { css } from "styled-components";

const Header = styled.div`
  display: ${({ $display }) => ($display ? $display : "block")};
  font-style: normal;
  font-family: Mukta, sans-serif;
  text-align: ${({ $align }) => ($align ? $align : "center")};
  color: ${({ $color }) => ($color ? $color : "#34313b")};
  font-weight: ${({ $weight }) => ($weight ? $weight : 400)};
  ${({ $size }) => {
    switch ($size) {
      case "h2":
        return css`
          font-size: 2rem;
          line-height: 2.5rem;
          letter-spacing: ;
        `;
      case "h3":
        return css`
          font-size: 1.875rem;
          letter-spacing: -0.14px;
          line-height: 1.2em;
        `;
      case "h4":
        return css`
          font-size: 1.375rem;
          letter-spacing: -0.5px;
          line-height: 1.2em;
        `;
      case "h5":
        return css`
          font-size: 1.0625rem;
          letter-spacing: -0.5px;
          line-height: 1.2em;
        `;
      case "h6":
        return css`
          font-size: 0.875rem;
          letter-spacing: 0em;
          line-height: 1.4em;
        `;
      default:
        return css`
          font-size: 3rem;
          letter-spacing: -2px;
          line-height: 1.2em;
        `;
    }
  }};
`;
export default Header;
