import React from "react";
import { useNavigate } from "react-router-dom";

import useApi from "api/useApi";
import ContactUsForm from "./components/ContactUsForm";
import Header from "components/Header";
import CardBody from "components/CardBody";

const ContactUs = () => {
  const { createContactUs } = useApi();
  const navigate = useNavigate();

  const handleSubmit = async (formData) => {
    const contactUsRes = await createContactUs(formData);
    contactUsRes?.status === 200 && navigate(`/success-contact`);
  };

  return (
    <CardBody className={"min-w-[72vw] max-w-[100vw]"}>
      <div className="flex flex-col gap-y-5">
        <Header $size="h3" $weight="600">
          How can we get in touch?
        </Header>
        <Header $size="h5" $weight="500" $color="#4B5563">
          If you're an existing customer, be sure to use the same email or phone
          number.
        </Header>
        <ContactUsForm onSubmit={handleSubmit} />
      </div>
    </CardBody>
  );
};
export default ContactUs;
