import React from 'react'
import CardBody from 'components/CardBody'
import Header from 'components/Header'
import { Button } from 'primereact/button'
import Switch from 'components/Switch'
import { useNavigate } from 'react-router-dom'

const LocationError = ({ responseCode }) => {
  const navigate = useNavigate();
  const isProd = process.env.REACT_APP_ENVIRONMENT === "production";

  return (
    <Switch condition={responseCode}>
      <Switch.Case when={406}>
        <CardBody className={"min-w-[72vw] max-w-[100vw]"}>
          <div className="flex flex-col items-center gap-y-5 mb-2">
              <Header $size="h3" $weight="600">
                  We're not in your area just yet
              </Header>
              <div className="flex flex-row self-center max-w-[560px] text-center">
                <Header $size="h5" $weight="500" $color="#4B5563">
                  We're expanding significantly and we can let you know as soon as
                  we have home services in your area.
                </Header>
              </div>
              <Button
                label="Contact Us"
                rounded
                onClick={(e) => { 
                  e.preventDefault();
                  e.stopPropagation();
                  navigate("/contact-us");
                }}
              />
              <Button
                link
                label="Return To Home"
                onClick={() => { 
                  const homeURL = isProd
                  ? "https://oneoshome.com/"
                  : "https://dev.oneoshome.com/";

                  window.open(homeURL, "_self");
                }}
              />
          </div>
        </CardBody>
      </Switch.Case>
    </Switch>

  )
}

export default LocationError