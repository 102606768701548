import { Routes, Route, Navigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { Context } from "./providers/Context";
import { fetchAuthSession } from "aws-amplify/auth";
import Loader from "./components/Loader";
import SnackbarComponent from "./components/SnackbarComponent";
import ScrollToTop from "./components/ScrollToTop";
import Root from "components/Root";
import SignIn from "./pages/SignIn";
import Calendar from "./pages/Calendar";
import Payment from "./pages/Payment";
import Account from "./pages/Account";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Unsubscribed from "./pages/Unsubscribed";
import Onboard from "pages/Onboard";
import OnboardComplete from "pages/OnboardComplete";
import OnboardLocation from "pages/OnboardLocation";
import OnboardContact from "pages/OnboardContact";
import OnboardName from "pages/OnboardName";
import OnboardPayment from "pages/OnboardPayment";
import ContactUs from "pages/ContactUs";
import SuccessContact from "pages/SuccessContact";
import ConfirmSignUp from "pages/ConfirmSignUp";
import OnboardConfirmSignUp from "pages/OnboardConfirmSignUp";
import OnboardCustomPackage from "pages/OnboardCustomPackage";

const ProtectedRoute = ({ children }) => {
  const { isAuthorized } = useContext(Context);
  if (!isAuthorized) {
    return <Navigate to="/" />;
  }
  return children;
};

const Routing = () => {
  const { isLoading, setIsAuthorized, isAuthorized } = useContext(Context);
  const [firstLoading, setFirstLoading] = useState(false);

  // used to keep auth when refreshed
  useEffect(() => {
    // move to context did not work well when using prev ls set up
    const fun = async () => {
      setFirstLoading(false);
      const session = await fetchAuthSession();
      const token = session?.tokens?.idToken.toString();
      if (token) {
        await setIsAuthorized(true);
      }
      setFirstLoading(true);
    };
    fun();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {/* Show a special loader when calculating address otherwise spinner */}
      <Loader open={isLoading} />
      <SnackbarComponent />
      <ScrollToTop />
      {firstLoading && (
        <Routes>
          {/* Onboarding routes*/}
          <Route path="/onboard" element={<Onboard />}>
            <Route index element={<OnboardLocation />} />
            <Route path="custom-package" element={<OnboardCustomPackage />} />
            <Route path="contact" element={<OnboardContact />} />
            <Route path="information" element={<OnboardName />} />
            <Route path="payment" element={<OnboardPayment />} />
            <Route path="complete" element={<OnboardComplete />} />
          </Route>

          {/* Other routes */}
          <Route element={<Root />}>
            <Route
              path="/"
              element={
                <Navigate replace to="/onboard" />
              }
            />
            <Route path="/*" element={<Navigate replace to="/" />} />
          </Route>
        </Routes>
      )}
    </>
  );
};

export default Routing;
