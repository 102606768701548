import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { OnboardContext } from "providers/OnboardContext";
import InputField from "components/InputField";
import { Button } from "primereact/button";
import useApi from "api/useApi";
import useAuth from "api/useAuth";

const OnboardConfirmSignUpForm = () => {
  const { getOnboardData } = useContext(OnboardContext);
  const initialValues = {
    code: "",
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { ...initialValues },
  });

  const { confirmAccount } = useApi();
  const { handleResendConfirmCode } = useAuth();
  return (
    <form
      id="onboard-confirm-signup-form"
      className="flex flex-col items-center"
    >
      <div className="flex flex-col gap-y-5 full-width-xs w-[250px]">
        <InputField
          register={register}
          registerName={"code"}
          type={"text"}
          label="Confirmation code"
          placeholder=""
          required={"Code is required"}
          errorMessage={errors?.code?.message}
        />
        <div className="flex flex-col justify-center gap-y-2.5">
          <div className="flex flex-row justify-center">
            <Button
              onClick={handleSubmit(async (data) => {
                await confirmAccount(data);
              })}
              label="Confirm"
              rounded
            />
          </div>
          <div className="flex flex-row justify-center">
            <Button
              onClick={(e) => {
                e.preventDefault();
                const username = getOnboardData("oe");
                handleResendConfirmCode({ username });
              }}
              label="Resend code"
              link
            />
          </div>
        </div>
      </div>
    </form>
  );
};
export default OnboardConfirmSignUpForm;
