import React from "react";
import { useForm } from "react-hook-form";
import InputField from "components/InputField";
import { Button } from "primereact/button";
import useAuth from "api/useAuth";

// used to confirm first sign in
const ConfirmSignUpForm = () => {
  const initialValues = {
    code: "",
    username: "",
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: { ...initialValues },
  });
  const [username] = watch(["username"]);

  const { handleResendConfirmCode, handleSignInConfirm } = useAuth();
  return (
    <form id="confirm-signin-form" className="flex flex-col items-center">
      <div className="flex flex-col gap-y-5 full-width-xs w-[250px]">
        <InputField
          register={register}
          registerName={"username"}
          type={"email"}
          label="Email Address"
          placeholder=""
          required={"Email is required"}
          pattern={{
            value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
            message: "Enter a valid email address",
          }}
          errorMessage={errors?.username?.message}
        />
        <InputField
          register={register}
          registerName={"code"}
          type={"text"}
          label="Confirmation code"
          placeholder=""
          required={"Code is required"}
          errorMessage={errors?.code?.message}
        />
        <div className="flex flex-col justify-center gap-y-2.5">
          <div className="flex flex-row justify-center">
            <Button
              onClick={handleSubmit(async (data) => {
                await handleSignInConfirm(data);
              })}
              label="Confirm"
              rounded
            />
          </div>
          <div className="flex flex-row justify-center">
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleResendConfirmCode({ username });
              }}
              label="Resend code"
              link
            />
          </div>
        </div>
      </div>
    </form>
  );
};
export default ConfirmSignUpForm;
