import React from "react";
import { useForm } from "react-hook-form";
import InputField from "components/InputField";
import { Button } from "primereact/button";
import useAuth from "api/useAuth";

const ForgotPasswordForm = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const { handleResetPassword } = useAuth();

  return (
    <form id="forgot-password-form" className="flex flex-col items-center">
      <div className="flex flex-col gap-y-5 full-width-xs w-[250px]">
        <InputField
          register={register}
          registerName={"username"}
          type={"email"}
          label="Email Address"
          placeholder=""
          required={"Email is required"}
          pattern={{
            value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
            message: "Enter a valid email address",
          }}
          errorMessage={errors?.username?.message}
        />

        <div className="flex flex-row justify-center w-full">
          <Button
            onClick={handleSubmit(async (data) => {
              await handleResetPassword(data);
            })}
            label="Get reset password code"
            rounded
          />
        </div>
      </div>
    </form>
  );
};

export default ForgotPasswordForm;
