import * as React from "react";

export default function Loader({ open }) {
  return (
    <div
      style={{ visibility: open ? "visible" : "hidden", zIndex: 1000000000 }}
      className={"loading-container"}
    >
      <div className={"loader"}></div>
    </div>
  );
}
