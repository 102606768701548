import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Header from "components/Header";
import { Button } from "primereact/button";
import { SelectButton } from "primereact/selectbutton";
import { ScrollPanel } from "primereact/scrollpanel";
import PackageContainer from "./components/PackageContainer";
import { OnboardContext } from "providers/OnboardContext";
import { Context } from "providers/Context";
import { emptyItem } from "utils/emptyItem";

const OnboardCustomPackage = () => {
  const navigate = useNavigate();
  const {
    saveOnboardData,
    getOnboardData,
    setFormStep,
    customPackageSelection,
    setCustomPackageSelect,
  } = useContext(OnboardContext);
  const { openInfoSnackbar } = useContext(Context);

  const [selectedEssentials, setSelectedEssentials] = useState({});
  const [selectedComplete, setSelectedComplete] = useState({});
  const [toggle, setToggle] = useState(false); // false is essentials, true is complete
  const [totalSavings, setTotalSavings] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [error, setError] = useState("");

  const lawn_info = getOnboardData("ols");
  const house_info = getOnboardData("ohs");

  const toggleOptions = ["Essentials", "Complete"];
  // eslint-disable-next-line
  const [data, setData] = useState(() => {
    const unsortedData = getOnboardData("options");

    // sort data by page order
    const sortedData = unsortedData.sort((a, b) => {
      return a.page_order - b.page_order;
    });
    return sortedData;
  });
  const calculateTotal = (values, type) => {
    return Object.entries(values).reduce((total, [key, value]) => {
      let val = type === "savings" ? value?.savings_amount : value?.price;
      if (typeof total === "string") {
        total = Number(total);
      }
      if (typeof val === "string") {
        val = Number(val);
      }
      let newTotal = total + val;

      return Number(newTotal).toFixed(2);
    }, 0);
  };

  const formatCurrency = (value) => {
    return Number(Number(value).toFixed(2)).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  // change depending on price ID for none
  const numOfCategories = (values) => {
    return Object.entries(values).reduce((total, [key, value]) => {
      return value?.price_id !== 0 ? total + 1 : total;
    }, 0);
  };

  useEffect(() => {
    //setting up defaults
    if (
      data &&
      (customPackageSelection?.essentials == null ||
        customPackageSelection?.complete == null ||
        Object.values(customPackageSelection.essentials).length === 0 ||
        Object.values(customPackageSelection.complete).length === 0)
    ) {
      data.forEach((item) => {
        item?.complete_default
          ? setSelectedComplete((previousValue) => ({
              ...previousValue,
              [item?.category.replace(/\s/g, "")]: item?.complete_default,
            }))
          : setSelectedComplete((previousValue) => ({
              ...previousValue,
              [item?.category.replace(/\s/g, "")]: emptyItem,
            }));

        item?.essentials_default
          ? setSelectedEssentials((previousValue) => ({
              ...previousValue,
              [item?.category.replace(/\s/g, "")]: item?.essentials_default,
            }))
          : setSelectedEssentials((previousValue) => ({
              ...previousValue,
              [item?.category.replace(/\s/g, "")]: emptyItem,
            }));
      });
      // eslint-disable-next-line
    } else if (customPackageSelection) {
      console.log("trying to set up saved defaults");
      setSelectedEssentials(customPackageSelection?.essentials);
      setSelectedComplete(customPackageSelection?.complete);
      setToggle(customPackageSelection?.toggleComplete);
    }
  }, [data]);

  // Determine completed packages amounts
  useEffect(() => {
    setTotalPrice(calculateTotal(selectedComplete, "price"));
    setTotalSavings(calculateTotal(selectedComplete, "savings"));
  }, [selectedComplete]);

  // Determine essential pacakge amounts
  useEffect(() => {
    setTotalPrice(calculateTotal(selectedEssentials));
    setTotalSavings(calculateTotal(selectedEssentials, "savings"));
  }, [selectedEssentials]);

  // Set the selected on toggle
  useEffect(() => {
    setCustomPackageSelect({
      essentials: { ...selectedEssentials },
      complete: { ...selectedComplete },
      toggleComplete: toggle,
    });

    let selected = toggle ? selectedComplete : selectedEssentials;
    setTotalPrice(calculateTotal(selected, "price"));
    setTotalSavings(calculateTotal(selected, "savings"));
  }, [toggle, selectedComplete, selectedEssentials]);

  useEffect(() => {
    if (
      customPackageSelection &&
      Object.values(customPackageSelection?.essentials).length > 0 &&
      Object.values(customPackageSelection?.complete).length > 0
    ) {
      setToggle(customPackageSelection?.toggleComplete);
      setSelectedEssentials(customPackageSelection?.essentials);
      setSelectedComplete(customPackageSelection?.complete);
      openInfoSnackbar("Loaded Previous Session");
    }
    setFormStep(1);
  }, []);

  const handleConfirm = () => {
    // Check if at least two different categories are selected
    const totalCategories = numOfCategories(selectedEssentials);
    if (totalCategories < 2) {
      setError("*Minimum of 1 service in 2 different categories");
    } else {
      let dataToClean = toggle ? selectedComplete : selectedEssentials;

      // remove none from items and then only return price_ids
      const cleanedData = Object.values(dataToClean)
        .filter((item) => item.price !== 0)
        .map((item) => item.price_id);

      saveOnboardData({ op: cleanedData });
      navigate("/onboard/contact");
    }
  };

  return (
    <div className="md:w-11/12 plain-card landscape:min-h-[120vh] portrait:min-h-[85vh] md:min-h-[85vh] sm:min-h-[85vh]">
      <div className="flex flex-col flex-nowrap xl:flex-wrap xl:flex-row pb-1">
        <div
          className={`flex flex-col lg:flex-row flex-nowrap items-center gap-y-2 grow-0 ${
            window.location.hostname === "agent-dev.oneoshome.com" ||
            window.location.hostname === "agent.oneoshome.com" ||
            process.env.REACT_APP_ENVIRONMENT === "agent"
              ? "w-full justify-between"
              : ""
          }`}
        >
          <div className="flex flex-1 xl:flex-none flex-col justify-center order-1 md:items-start sm:flex-1">
            <Header
              $size="h3"
              $weight="700"
              $align="left"
              className="max-content-sm"
            >
              Build your package
            </Header>
            <span className="hidden lg:flex italic text-xs pt-2">
              * Must have at least 1 service in 2 different categories
            </span>
          </div>
          <div className="flex flex-1 xl:flex-none flex-row gap-x-4 justify-end items-center order-2 lg:order-3 md:justify-start">
            <span id="package-toggle">
              <SelectButton
                allowEmpty={false}
                value={toggle ? "Complete" : "Essentials"}
                onChange={(e) => {
                  setToggle(!toggle);
                }}
                options={toggleOptions}
              />
            </span>
          </div>
          <div className="flex flex-1 xl:flex-none xl:min-w-[fit] flex-col xl:flex-row order-3 lg:order-2 items-center mx-10 gap-x-10">
            <div className="flex flex-col items-center xl:items-start">
              <span className="text-sm font-semibold text-[#54505E] lg:text-xl">
                Monthly Price: ${formatCurrency(totalPrice / 100)}
              </span>
              <span className="text-xs font-normal text-[#54505E]">
                <i
                  className="pi pi-home mr-0.5"
                  style={{ fontSize: "0.75rem" }}
                  alt="icon of a house"
                ></i>
                Home size: {house_info?.size}
              </span>
              <div className="flex flex-row mr-0.5">
                <img src="/images/grass.svg" alt="image of a grass icon" />

                <span className="text-xs font-normal text-[#54505E]">
                  Lawn size:{" "}
                  {typeof lawn_info?.lawn_sqft === "number"
                    ? `${lawn_info?.lawn_sqft} sqft`
                    : lawn_info?.size}
                </span>
              </div>
            </div>
            <div className="flex flex-col items-center xl:items-start">
              <span className="text-xs font-normal text-[#54505E]">
                Regular Annual Price: $
                {formatCurrency((totalPrice / 100 + totalSavings / 100) * 12)}
              </span>
              <span className="text-xs font-normal text-[#54505E]">
                OneOs Annual Price: ${formatCurrency((totalPrice / 100) * 12)}
              </span>
              <span className="text-xs font-bold text-[#4A8FE7]">
                You Save: ${formatCurrency((totalSavings / 100) * 12)} Every
                Year!
              </span>
              <span className="text-center italic text-xs order-4 lg:hidden">
                * Must have at least 1 service in 2 different categories
              </span>
            </div>
          </div>
        </div>

        {window.location.hostname !== "agent-dev.oneoshome.com" &&
        window.location.hostname !== "agent.oneoshome.com" &&
        process.env.REACT_APP_ENVIRONMENT !== "agent" ? (
          <div className="flex flex-1 flex-row text-nowrap order-4 justify-center py-2 h-fit self-center xl:ml-5">
            <Button
              icon="pi pi-comment"
              label="Talk with a specialist"
              rounded
              outlined
              size="small"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate("/contact-us");
              }}
            />
          </div>
        ) : null}
      </div>

      {data ? (
        <ScrollPanel className="scroll-content border-t-[1px] border-[#34313B]/50 pt-4">
          {data.map((category, index) =>
            category?.category === "Windows" ? (
              <div key={index}>
                <PackageContainer
                  items={category?.options}
                  category={"Interior Windows"}
                  selectedCategory={
                    toggle ? selectedComplete : selectedEssentials
                  }
                  setSelectedCategory={
                    toggle ? setSelectedComplete : setSelectedEssentials
                  }
                  subCategory="interior"
                  formatCurrency={formatCurrency}
                />
                <PackageContainer
                  items={category?.options}
                  category={"Exterior Windows"}
                  subCategory="exterior"
                  selectedCategory={
                    toggle ? selectedComplete : selectedEssentials
                  }
                  setSelectedCategory={
                    toggle ? setSelectedComplete : setSelectedEssentials
                  }
                  formatCurrency={formatCurrency}
                />
              </div>
            ) : (
              <PackageContainer
                key={index}
                items={category?.options}
                category={category?.category}
                selectedCategory={
                  toggle ? selectedComplete : selectedEssentials
                }
                setSelectedCategory={
                  toggle ? setSelectedComplete : setSelectedEssentials
                }
                categorySubtype={category?.metadata__price_category}
                formatCurrency={formatCurrency}
                toggle={toggle}
              />
            )
          )}
          {error && (
            <div className="text-center italic text-xs text-red">{error}</div>
          )}
          <div className="flex flex-row w-full justify-center pb-4 mt-4 ">
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleConfirm();
              }}
              label="Confirm Package"
              rounded
            />
          </div>
        </ScrollPanel>
      ) : null}
    </div>
  );
};

export default OnboardCustomPackage;
