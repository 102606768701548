import React from "react";
import CardBody from "components/CardBody";
import Header from "components/Header";
import ConfirmSignUpForm from "./components/ConfirmSignUpForm";

const ConfirmSignUp = () => {
  return (
    <div className="flex flex-col gap-y-7 w-full items-center">
      <CardBody className={"max-w-[100vw] min-w-[72vw]"}>
        <div className="flex flex-col gap-y-5 justify-center">
          <div className="flex flex-col self-center w-4/5">
            <Header $size="h3" $weight="600">
              Confirm your account
            </Header>
          </div>
          <Header $size="h5" $weight="500" $color="#4B5563">
            A confirmation code was sent to your email.
          </Header>
          <ConfirmSignUpForm />
        </div>
      </CardBody>
    </div>
  );
};

export default ConfirmSignUp;
