import React, { useState, useEffect } from 'react';

const AppleBusinessChat = () => {
    const [isAppleDevice, setIsAppleDevice] = useState(false);
  
    useEffect(() => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        setIsAppleDevice(true);
      } else if (/Macintosh/.test(userAgent) || userAgent.includes("Mac")) {
        setIsAppleDevice(true);
      }
    }, []);
  
    if (!isAppleDevice) {
      return null;
    }

    return (
      <button className='flex items-center justify-center h-[2.5rem] min-w-fit w-fit' type="button" onClick={() => {window.open('https://bcrw.apple.com/urn:biz:73b0f7f8-76f0-4611-98c9-db9860f67f8f', '_blank')}}>
          <img className='h-[100%]' src="/images/Messages for Business - Get Help - White.svg" alt="Apple Messages for Business, Get Help" />
      </button>
    );
  };

export default AppleBusinessChat