import axios from "axios";
import { fetchAuthSession } from "aws-amplify/auth";
import useAuth from "./useAuth";
import * as Sentry from "@sentry/react";

const baseURL = process.env.REACT_APP_API_URL + `api/`;

// Used for routes that do not need to be authenticated
export const baseAxios = axios.create({ baseURL });

baseAxios.interceptors.request.use(
  (config) => {
    // Custom logic for request interceptor
    return config;
  },
  (error) => {
    Sentry.captureException(error);
    return Promise.reject(error);
  }
);

baseAxios.interceptors.response.use(
  (response) => {
    // Custom logic for response interceptor
    return response;
  },
  (error) => {
    const originalRequest = error?.config;

    // Used for measurements API call since it can take longer than 1 minute and
    // backend timeouts after 30s
    if (
      originalRequest?.url?.includes("measurements") &&
      (error?.message?.includes("timeout") ||
        error?.message?.includes("Network Error")) &&
      originalRequest.retry > 0
    ) {
      originalRequest.retry -= 1;
      return baseAxios(originalRequest);
    }
    Sentry.captureException(error);
    return Promise.reject(error);
  }
);

// Hook connecting with interceptor and state
const useAxios = () => {
  const authAxios = axios.create({ baseURL });
  const { handleSignOut } = useAuth();

  authAxios.interceptors.request.use(
    async (config) => {
      const session = await fetchAuthSession();
      const token = session?.tokens?.idToken.toString();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      Sentry.captureException(error);
      // console.log("request error", error);
      Promise.reject(error);
    }
  );
  authAxios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      Sentry.captureException(error);
      // console.log("Error in axios res", error);
      const originalRequest = error.config;

      // 401 is if unauth
      if (error.response.status === 401 && error.response.data?.detail !== "User profile is incomplete" && !originalRequest._retry) {
        originalRequest._retry = true;
        // console.log("401 error and retrying");
        // Refresh tokens handled automatically. Sign out user if unauth
        await handleSignOut();
        return Promise.reject(error);
      } else if (error.response.status === 401 && error.response.data?.detail === "User profile is incomplete") {
        return Promise.reject(error);
      }
      return Promise.reject(error);
    }
  );

  return authAxios;
};

export default useAxios;
