import React, { useContext, useState, useEffect } from "react";
import CardBody from "components/CardBody";
import Header from "components/Header";
import LoginForm from "./components/LoginForm";
import { Context } from "providers/Context";
import useAuth from "api/useAuth";
import ConfirmNewPasswordForm from "./components/ConfirmNewPasswordForm";
import { OnboardContext } from "providers/OnboardContext";
import { fetchAuthSession } from "aws-amplify/auth";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

const SignIn = () => {
  const { isAuthorized, isOnboarded } = useContext(Context);
  const { handleSignOut } = useAuth();
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  const getUser = async () => {
    if(isAuthorized){
      const session = await fetchAuthSession();
      const token = session?.tokens?.idToken.payload;
      setUser({
        email: token.email,
        name: `${token.name} ${token.family_name}`,
      });
    }else{
      return null;
    }
  };

  useEffect(() => {
    getUser();
  }, []);


  const { isConfirmingNewPassword } = useContext(OnboardContext);

  return (
    <div className="flex flex-col gap-y-7 w-full items-center">
      <CardBody className={"max-w-[100vw] min-w-[72vw]"}>
        <div className="flex flex-col gap-y-5 justify-center">
          {!isAuthorized ? 
            <>
              <div className="flex flex-col self-center w-4/5">
                <Header $size="h3" $weight="600">
                  {isConfirmingNewPassword ? "Change temporary password" : "Log on to oneOS Home"}
              </Header>
              </div>
              {isConfirmingNewPassword ? <ConfirmNewPasswordForm /> : <LoginForm />}
            </>
            :
            user && (
              <div className="flex flex-col self-center w-4/5 gap-y-4">
                <Header $size="h4" $weight="600">
                  It appears you've not completed the onboarding process yet. An agent will be in contact with you soon via email to complete the process.
                </Header>
                <Button label="Sign Out" onClick={() => handleSignOut()}/>
              </div>
            )
          }
        </div>
      </CardBody>
    </div>
  );
};

export default SignIn;
