import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { Context } from "providers/Context";
import { OnboardContext } from "providers/OnboardContext";
import {
  signUp,
  signIn,
  signInWithRedirect,
  confirmSignUp,
  confirmSignIn,
  signOut,
  getCurrentUser,
  autoSignIn,
  resendSignUpCode,
  resetPassword,
  confirmResetPassword,
  updatePassword,
} from "aws-amplify/auth";
import * as Sentry from "@sentry/react";

const useAuth = () => {
  const navigate = useNavigate();
  const { clearOnboardData, setIsConfirmingNewPassword } = useContext(OnboardContext);

  const {
    setIsLoading,
    setIsAuthorized,
    setUserData,
    openErrorSnackbar,
    openInfoSnackbar,
  } = useContext(Context);

  const handleSignUp = async ({ username, password }) => {
    try {
      const { userId } = await signUp({
        username,
        password,
        options: {
          autoSignIn: true, // This enables the auto sign-in flow.
        },
      });
      return userId;
    } catch (error) {
      Sentry.captureException(error);
      // console.log("Error signing up:", error);
    }
  };

  // used for onboarding
  const handleSignUpConfirmation = async ({ username, code }) => {
    try {
      const { nextStep } = await confirmSignUp({
        username,
        confirmationCode: code,
      });
      // console.log("Sign up confirmation", nextStep);
    } catch (error) {
      Sentry.captureException(error);
      // console.log("Error confirming sign up", error);
      throw error;
    }
  };

  // used if user drops out of flow in confirm code step
  const handleSignInConfirm = async ({ username, code }) => {
    try {
      setIsLoading(true);
      const { nextStep } = await confirmSignUp({
        username,
        confirmationCode: code,
      });
      // console.log("Sign in confirmation", nextStep);
      setIsLoading(false);
      clearOnboardData();
      openInfoSnackbar("Code has been confirmed");
      navigate("/");
    } catch (error) {
      Sentry.captureException(error);
      setIsLoading(false);
      // console.log("Error confirming sign up", error);
      openErrorSnackbar(error?.message);
    }
  };

  const handleAutoSignIn = async () => {
    try {
      const signInRes = await autoSignIn();
      // console.log("Auto sign in:", signInRes);
      setIsAuthorized(true);
    } catch (error) {
      Sentry.captureException(error);
      // console.log("Error auto sign up", error);
    }
  };
  const handleResendConfirmCode = async ({ username }) => {
    try {
      setIsLoading(true);
      await resendSignUpCode({ username });
      setIsLoading(false);
      openInfoSnackbar(
        "If you have an account, the code was sent to your email."
      );
    } catch (error) {
      Sentry.captureException(error);
      setIsLoading(false);
      // console.log("Error resending code", error);
      openErrorSnackbar("Unable to send confirmation code.");
    }
  };

  const handleSignIn = async ({ username, password }) => {
    try {
      setIsLoading(true);
      const { nextStep } = await signIn({
        username,
        password,
        options: {
          authFlowType: "USER_PASSWORD_AUTH",
        },
      });
      // console.log(nextStep?.signInStep);
      // Determine what to do next
      switch (nextStep?.signInStep) {
        case "CONFIRM_SIGN_UP":
          setIsLoading(false);
          navigate("/confirm");
          break;
        case "RESET_PASSWORD":
          setIsLoading(false);
          navigate("/forgot-password");
          break;
        case "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED":
          setIsConfirmingNewPassword(true);
          setIsLoading(false);
          break;
        case "DONE":
          setIsLoading(false);
          setIsAuthorized(true);
          navigate("/dashboard");
          break;
        default:
          throw new Error("Unable to sign in.");
      }
    } catch (error) {
      Sentry.captureException(error);
      setIsLoading(false);
      // console.log("error signing in", error);
      openErrorSnackbar(error?.message);
    }
  };

  const handleSignInWithRedirect = async ({ provider }) => {
    try {
      setIsLoading(true);
      const redirectResult = await signInWithRedirect({ provider: provider });
      console.log("redirectResult", redirectResult);
    } catch (error) {
      console.log("Error signing in with oauth", error);
      openErrorSnackbar("Error signing in with oauth");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignOut = async () => {
    try {
      setIsLoading(true);
      await signOut();
      setIsAuthorized(false);
      setUserData({});
      setIsLoading(false);
      navigate("/");
    } catch (error) {
      Sentry.captureException(error);
      setIsLoading(false);
      // console.log("error signing out: ", error);
    }
  };

  const handleGetUserId = async () => {
    try {
      const { userId } = await getCurrentUser();
      return { id: userId };
    } catch (error) {
      Sentry.captureException(error);
      // console.log("Error getting user id", error);
      throw new Error("Unable to complete process. Please try again.");
    }
  };

  // Password
  const handleResetPassword = async ({ username }) => {
    try {
      setIsLoading(true);
      const res = await resetPassword({ username });
      // console.log(res, "trying to reset password");
      setIsLoading(false);
      openInfoSnackbar(
        "If you have an account, the reset code was sent via e-mail."
      );
      navigate("/reset-password");
    } catch (error) {
      Sentry.captureException(error);
      setIsLoading(false);
      // console.log("Error in resetting password", error);
      openErrorSnackbar("Unable to reset password right now.");
    }
  };

  const handleConfirmNewPassword = async ({
    newPassword
  }) => {
    try {
      setIsLoading(true);
      await confirmSignIn({ challengeResponse: newPassword });
      setIsLoading(false);
      openInfoSnackbar("Password changed successfully.");
      window.location.reload();
    } catch (error) {
      Sentry.captureException(error);
      setIsLoading(false);
      // console.log("Error with confirming password reset", error);
      const errorMessage = error?.message
        ? error?.message
        : "Unable to reset password right now.";
      openErrorSnackbar(errorMessage);
    } finally {
      setIsConfirmingNewPassword(false);
    }
  };

  const handleConfirmResetPassword = async ({
    confirmationCode,
    newPassword,
    username,
  }) => {
    try {
      setIsLoading(true);
      await confirmResetPassword({
        confirmationCode,
        newPassword,
        username,
      });
      setIsLoading(false);
      openInfoSnackbar("Password changed successfully.");
      navigate("/");
    } catch (error) {
      Sentry.captureException(error);
      setIsLoading(false);
      // console.log("Error with confirming password reset", error);
      const errorMessage = error?.message
        ? error?.message
        : "Unable to reset password right now.";
      openErrorSnackbar(errorMessage);
    }
  };

  const handleUpdatePassword = async ({ newPassword, oldPassword }) => {
    try {
      setIsLoading(true);
      await updatePassword({ newPassword, oldPassword });
      setIsLoading(false);
      openInfoSnackbar("Password changed successfully.");
    } catch (error) {
      Sentry.captureException(error);
      setIsLoading(false);
      // console.log("Error with updating password:", error);
      const errorMessage = error?.message
        ? error?.message
        : "Unable to change password right now.";
      openErrorSnackbar(errorMessage);
    }
  };

  return {
    handleSignIn,
    handleSignInWithRedirect,
    handleSignUp,
    handleSignOut,
    handleAutoSignIn,
    handleGetUserId,
    handleSignInConfirm,
    handleUpdatePassword,
    handleResetPassword,
    handleResendConfirmCode,
    handleConfirmNewPassword,
    handleSignUpConfirmation,
    handleConfirmResetPassword,
  };
};
export default useAuth;
