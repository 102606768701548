import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import Routing from "./Routing";
import AgentRouting from "./AgentRouting";
import { BrowserRouter } from "react-router-dom";
import { AuthContextProvider } from "./providers/Context";
import { OnboardContextProvider } from "providers/OnboardContext";
import { PrimeReactProvider } from "primereact/api";
import LiveChat from "./components/LiveChat";
import { Amplify } from "aws-amplify";
import SentryErrorFallback from "components/SentryErrorFallback";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import PostHogPageviewTracker from "./components/PostHogPageviewTracker/";
import { isCookieSet } from "./utils/functions";
import "./styles/index.scss";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/primereact.min.css";

/** Sentry configuration */
Sentry.init({
  environment: process.env.REACT_APP_ENVIRONMENT,
  dsn: "https://5212375ddd7dfb1083c0fdd0d3571a96@o4506145467596800.ingest.sentry.io/4506781477896192",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions

  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  beforeSend: (event) => {
    if (
      window.location.hostname === "localhost" ||
      window.location.hostname === "127.0.0.1"
    ) {
      event.environment = "development";
      return event;
    }
    return event;
  },
});
/**  Auth related configuration */
Amplify.configure({
  Auth: {
    Cognito: {
      //  Amazon Cognito User Pool ID
      userPoolId: process.env.REACT_APP_AWS_USER_POOL,
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolClientId: process.env.REACT_APP_AWS_CLIENT_ID,
      // OPTIONAL - Set to true to use your identity pool's unauthenticated role when user is not logged in
      allowGuestAccess: false,
      // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
      signUpVerificationMethod: "code", // 'code' | 'link'x
      loginWith: {
        oauth: {
          domain: process.env.REACT_APP_AWS_COGNITO_DOMAIN,
          scopes: ["email", "openid", "phone", "profile"],
          redirectSignIn: [
            "https://app-dev.oneoshome.com/",
            "http://localhost:3000/",
          ],
          redirectSignOut: [
            "https://app-dev.oneoshome.com/",
            "http://localhost:3000/",
          ],
          responseType: "code",
          providers: ["Google"],
        },
        email: true,
      },
    },
  },
});

const consentCookieSet = isCookieSet(process.env.REACT_APP_POSTHOG_KEY);
// POSTHOG configuration
if (consentCookieSet) {
  posthog.init(process.env.REACT_APP_POSTHOG_KEY, {
    api_host: process.env.REACT_APP_POSTHOG_HOST,
    person_profiles: "identified_only",
    capture_pageview: false,
    loaded: function (ph) {
      if (process.env.REACT_APP_ENVIRONMENT !== "production") {
        ph.opt_out_capturing();
        ph.set_config({ disable_session_recording: true });
      }
    },
  });
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <PostHogProvider client={posthog}>
      <Sentry.ErrorBoundary fallback={<SentryErrorFallback />}>
        <BrowserRouter>
          {consentCookieSet && <PostHogPageviewTracker />}
          <PrimeReactProvider>
            <AuthContextProvider>
              <OnboardContextProvider>
                {window.location.hostname === "agent-dev.oneoshome.com" ||
                window.location.hostname === "agent.oneoshome.com" ||
                process.env.REACT_APP_ENVIRONMENT === "agent" ? (
                  <AgentRouting />
                ) : (
                  <Routing />
                )}
              </OnboardContextProvider>
            </AuthContextProvider>
          </PrimeReactProvider>
        </BrowserRouter>
        {process.env.REACT_APP_ENVIRONMENT !== "production" && <LiveChat />}
      </Sentry.ErrorBoundary>
    </PostHogProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
