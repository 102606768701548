import React, { useState, useRef, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { APILoader } from "@googlemaps/extended-component-library/react";
import { useForm, Controller } from "react-hook-form";
import { PatternFormat } from "react-number-format";
import InputField from "components/InputField";
import UnregisteredField from "components/UnregisteredField";
import { Button } from "primereact/button";
import { OnboardContext } from "../../../../providers/OnboardContext";

const ContactUsForm = ({ onSubmit }) => {
  const { getOnboardData } = useContext(OnboardContext);
  const navigate = useNavigate();
  const [address, setAddress] = useState({});
  const [locationBlur, setLocationBlur] = useState(false);

  const initialValues = {
    first_name: getOnboardData("ofn") ?? "",
    last_name: getOnboardData("oln") ?? "",
    email: getOnboardData("oe") ?? "",
    phone: getOnboardData("oph") ?? "",
    formatted_address: "",
  };
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: { ...initialValues },
  });

  // used for google autocomplete
  const inputRef = useRef();
  let autoCompleteRef = useRef();
  const options = {
    componentRestrictions: { country: "us" },
    fields: ["formatted_address", "place_id"],
  };

  const handleAddressInput = (address) => {
    if (address) {
      // eslint-disable-next-line
      const [street, city, stateWithZip, country] = address?.split(",");
      const [state, zipCode] = stateWithZip?.trimStart()?.split(" ");

      // Zipcode sometimes comes up empty
      if (zipCode !== undefined) {
        setAddress({
          city: city?.trimStart(),
          state,
          street_address: street?.trimStart(),
          postal_code: zipCode,
        });
        setValue("formatted_address", address);
      }
    }
  };

  const setUpAutoComplete = async () => {
    const { Autocomplete } = await APILoader.importLibrary("places");
    autoCompleteRef = new Autocomplete(inputRef?.current, options);
    autoCompleteRef.addListener("place_changed", async () => {
      const result = await autoCompleteRef.getPlace();
      if (result?.place_id) {
        handleAddressInput(result?.formatted_address);
      } else {
        setAddress({});
        setValue("formatted_address", "");
      }
    });
  };

  useEffect(() => {
    setUpAutoComplete();
    const prePopulatedAddress = getOnboardData("oa");
    if (prePopulatedAddress) {
      inputRef.current.value = `${prePopulatedAddress.street_address}, ${prePopulatedAddress.city}, ${prePopulatedAddress.state} ${prePopulatedAddress.postal_code}, USA`;
      setAddress(prePopulatedAddress);
      setValue(
        "formatted_address",
        `${prePopulatedAddress.street_address}, ${prePopulatedAddress.city}, ${prePopulatedAddress.state} ${prePopulatedAddress.postal_code}, USA`
      );
    }
    // eslint-disable-next-line
  }, []);

  return (
    <form id="contact-form" className="flex flex-col items-center">
      <APILoader apiKey={process.env.REACT_APP_MAPS_API_KEY} />
      <div className="flex flex-col gap-y-5 full-width-xs w-fit">
        <div className="flex flex-col self-center gap-y-2.5 w-[250px]">
          <InputField
            register={register}
            registerName={"first_name"}
            label="First Name"
            placeholder=""
            required={"First name is required"}
            pattern={{
              value: /[^\s]+/,
              message: "Cannot be only spaces.",
            }}
            errorMessage={errors?.first_name?.message}
          />
          <InputField
            register={register}
            registerName={"last_name"}
            label="Last Name"
            placeholder=""
            required={"Last name is required"}
            pattern={{
              value: /[^\s]+/,
              message: "Cannot be only spaces.",
            }}
            errorMessage={errors?.last_name?.message}
          />
          <InputField
            register={register}
            registerName={"email"}
            type={"email"}
            label="Email Address"
            placeholder=""
            required={"Email is required"}
            pattern={{
              value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
              message: "Enter a valid email address",
            }}
            errorMessage={errors?.email?.message}
          />
          <Controller
            control={control}
            name={"phone"}
            rules={{
              required: "Phone is required",
              pattern: {
                value: /^\d{10}$/,
                message: "Ten digits required",
              },
            }}
            render={({ field: { onChange, name } }) => (
              <PatternFormat
                format="(###) ### ####"
                name={name}
                defaultValue={initialValues.phone}
                label="Phone Number"
                placeholder=""
                onValueChange={(value) => {
                  onChange(value?.value);
                }}
                errorMessage={errors?.phone?.message}
                customInput={UnregisteredField}
              />
            )}
          />
          <Controller
            control={control}
            name={"formatted_address"}
            rules={{
              required: "Valid address is required",
            }}
            render={({ field: { name } }) => (
              <UnregisteredField
                ref={inputRef}
                name={name}
                id="contact-us-address"
                label="Address"
                className={locationBlur ? "location-scale" : ""}
                placeholder=""
                aria-label="Address Details"
                errorMessage={errors?.formatted_address?.message}
                onFocus={() => {
                  setLocationBlur(false);
                }}
                onBlur={() => {
                  setLocationBlur(true);
                }}
              />
            )}
          />
        </div>
        <div className="flex flex-col justify-center self-center gap-y-2.5">
          <div className="flex flex-row justify-center w-fit max-w-[40vw]">
            <p className=" text-[14.5px] text-[#000000]">
              By clicking “Submit” I provide my signature consenting to contact
              from SWVE Management Holdco, LLC and its subsidiary companies at
              the number, email, and physical address I provided regarding
              products or services via live, automated or prerecorded call, or
              via text. Consent is not a condition of purchase. I can revoke
              this consent by emailing consent@wearesouthwind.com. I agree to
              the{" "}
              <a
                className=" text-blue-500 underline hover:text-blue-700"
                href="https://oneoshome.com/privacy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>{" "}
              and{" "}
              <a
                className=" text-blue-500 underline hover:text-blue-700"
                href="https://oneoshome.com/terms"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Use
              </a>{" "}
              including mandatory arbitration.
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-center gap-y-2.5">
          <div className="flex flex-row justify-center">
            <Button
              label="Submit"
              onClick={handleSubmit((formData) => {
                const { formatted_address, ...fieldsWanted } = formData;
                onSubmit({ ...fieldsWanted, ...address });
              })}
              rounded
            />
          </div>
          <div className="flex flex-row justify-center">
            <Button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (
                  (window.history?.length && window.history.length > 1) ||
                  window.history.state?.idx
                ) {
                  navigate(-1);
                } else {
                  navigate("/");
                }
              }}
              label="Back"
              link
            />
          </div>
        </div>
      </div>
    </form>
  );
};
export default ContactUsForm;
