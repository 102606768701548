import React, { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Nav from "components/Nav";
import { Context } from "providers/Context";
import { TieredMenu } from "primereact/tieredmenu";
import useApi from "api/useApi";
import * as Sentry from "@sentry/react";

const Root = () => {
  const {isAuthorized} = useContext(Context);
  const [ cardMenuIndex, setCardMenuIndex ] = useState(0);
  const { getBillingPortalApi } = useApi();
  const { openErrorSnackbar } = useContext(Context);
  const [billingLoading, setBillingLoading] = useState(false);

  const itemRenderer = (item) => {
    return (
      <a className={`flex align-items-center cardMenuItem ${ cardMenuItems[cardMenuIndex]?.label === item.label ? "cardMenuItem-selected" : ""}`}>
          <span className="mx-2">{item.label}</span>
      </a>
    );
  };

  useEffect(() => {
    for (let i = 0; i < cardMenuItems.length; i++) {
      if (window.location.pathname.includes(cardMenuItems[i].path)) {
        setCardMenuIndex(i);
      }
    }
  }, [window.location.pathname]);
  
  const cardMenuItems = [
    {
        label: 'Your Profile',
        path: "/profile",
        template: itemRenderer,
        command: () => window.location.href = "/dashboard/profile",
        disabled: billingLoading
    },
    {
        label: 'Membership',
        path: "/membership",
        template: itemRenderer,
        command: () => window.location.href = "/dashboard/membership",
        disabled: billingLoading
    },
    {
        label: 'Billing',
        path: "/billing",
        template: itemRenderer,
        command: async () => {
          setBillingLoading(true);
          getBillingPortalApi()
            .then((result) => {
              setTimeout(() => {
                window.open(result?.data?.portal_url, "_blank");
              });
            })
            .catch((reason) => {
              Sentry.captureException(reason);
              //Toast error
              openErrorSnackbar("Error getting billing portal");
            })
            .finally(() => {
              setBillingLoading(false);
            });
        },
        disabled: billingLoading
    }
  ]
  
  return (
    <div className={`w-full h-[100vh] ${isAuthorized ? "bg-[#F5F6F8]" : "bg-[#2C415A]"}`}>
      <Nav />
      <div className={`w-full flex flex-col items-center justify-start ${isAuthorized ? "bg-[#F5F6F8]" : "bg-[#2C415A]"} py-7 px-7 md:py-10 md:px-14`}>
        <div className='flex flex-col min-[768px]:flex-row justify-center min-[768px]:justify-around flex-nowrap h-fit w-full max-w-[100%] gap-10 relative'>
          {isAuthorized && <div className='h-full tiermenu-column'>
              <TieredMenu id='settings-tiermenu' model={cardMenuItems} breakpoint="767px" onChange={(e) => setCardMenuIndex(e.index)} />
          </div>}
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Root;
