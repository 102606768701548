import React from "react";
const PasswordGuide = () => {
  return (
    <div className="flex flex-col justify-center items-start self-center password-guide">
      <span className="font-semibold">Password must be:</span>
      <div className="flex flex-row gap-x-5">
        <ul>
          <li>at least 8 characters</li>
          <li>1 number</li>
          <li>1 letter</li>
        </ul>
        <ul>
          <li>1 lowercase</li>
          <li>1 upper</li>
          <li>1 special character</li>
        </ul>
      </div>
    </div>
  );
};
export default PasswordGuide;
