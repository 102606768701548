import Loader from 'components/Loader';
import React, { useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import NavLogo from 'components/NavLogo';

const SentryErrorFallback = () => {

  const openErrorFallbackSnackbar = (message) =>
  {
    toast.error(message, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }

  useEffect(() => {
    openErrorFallbackSnackbar('An error has occurred');
    setTimeout(() => {
      window.location.href = "/";
    }, 2000);
  }, []);

  //?Hard coded in nav bars as isAuthorized context would be undefined in this fallback
  return (
    <div className="w-full h-[100vh] bg-[#2C415A]">
      <div className="bg-[#FFF]">
        {/*Mobile Nav*/}
        <div className="flex md:hidden px-[20px] py-[10px] justify-between">
          <div className="flex flex-row items-center">
            <NavLogo />
          </div>
        </div>
        {/*Desktop Nav*/}
        <div className="hidden md:flex flex-row py-[8px] px-[20px]">
          <div className="w-1/6 flex items-center justify-center">
            <NavLogo />
          </div>
        </div>
      </div>

      <ToastContainer />
      <Loader open={true} />

    </div>
  )
}

export default SentryErrorFallback