import React from "react";
import { useForm } from "react-hook-form";
import InputField from "components/InputField";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

const NameForm = ({ onSubmit }) => {
  const navigate = useNavigate();

  const initialValues = {
    first_name: "",
    last_name: "",
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { ...initialValues },
  });

  return (
    <form
      id="onboard-name-form"
      className="flex flex-col items-center"
      onSubmit={handleSubmit((formData) => {
        onSubmit(formData);
      })}
    >
      <div className="flex flex-col gap-y-5 full-width-xs w-[250px]">
        <InputField
          register={register}
          registerName={"first_name"}
          label="First Name"
          placeholder=""
          required={"Name is required"}
          pattern={{
            value: /[^\s]+/,
            message: "Cannot be only spaces.",
          }}
          errorMessage={errors?.first_name?.message}
        />
        <InputField
          register={register}
          registerName={"last_name"}
          label="Last Name"
          placeholder=""
          required={"Name is required"}
          pattern={{
            value: /[^\s]+/,
            message: "Cannot be only spaces.",
          }}
          errorMessage={errors?.last_name?.message}
        />
        <div className="flex flex-col justify-center gap-y-2.5">
          <div className="flex flex-row justify-center">
            <Button type="submit" label="Continue" rounded />
          </div>
          <div className="flex flex-row justify-center">
            <Button
              onClick={(e) => {
                e.preventDefault();
                navigate("/onboard/contact");
              }}
              label="Back"
              link
            />
          </div>
        </div>
      </div>
    </form>
  );
};
export default NameForm;
