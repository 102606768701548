import React from "react";

const NavLogo = () => {
  const isProd = process.env.REACT_APP_ENVIRONMENT === "production";
  return (
      <a
        rel="noopener noreferrer"
        href={`${
          isProd ? "https://oneoshome.com/" : "https://dev.oneoshome.com/"
        }`}
        className="min-h-fit min-w-fit h-fit w-fit"
      >
        <img
          src="/images/nav-logos/home-dark-cropped.png"
          alt="OneOs Logo"
          height="48"
          width="136"
        />
      </a>
  );
};
export default NavLogo;
