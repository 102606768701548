import React, { useContext, useEffect, useState } from "react";
import Header from "components/Header";
import LocationForm from "./components/LocationForm";
import { OnboardContext } from "providers/OnboardContext";
import useApi from "api/useApi";
import LocationConfirmForm from "./components/LocationConfirmForm";
import LocationError from "./components/LocationError";
import { ProgressBar } from "primereact/progressbar";
import { lawnSizeOptions } from "./components/LocationForm/data";
import CardBody from "components/CardBody";

const OnboardLocationConfirm = () => {
  const { createAddressOnboard, getOnboardPrices } = useApi();
  const { locationResponseCode, locationResponse } = useContext(OnboardContext);
  const {
    setFormStep,
    isCalculating,
    confirmOnboardLocation,
    setConfirmOnboardLocation,
    saveOnboardData,
    clearOnboardData,
  } = useContext(OnboardContext);

  const [address, setAddress] = useState({});
  const [confirmNewAddress, setConfirmNewAddress] = useState(false);

  const handleConfirmSubmit = async (formData) => {
    if (formData?.lawnSize && typeof formData?.lawnSize !== "object") {
      formData.lawnSize = lawnSizeOptions.find((option) => {
        let [min, max] = option.code.split("_").slice(1);

        if (max === "plus") {
          return option;
        }

        if (formData?.lawnSize >= min && formData?.lawnSize <= max) {
          return option;
        }

        return null;
      });
    }

    saveOnboardData({ ohs: formData?.houseSize ?? {} });

    // If AI finds lawn size, save the exact sqft. If not, save the range.
    saveOnboardData({
      ols: {
        ...(formData?.lawnSize ?? {}),
        lawn_sqft: locationResponse?.sqft
          ? locationResponse?.sqft?.LAWN
          : formData?.lawnSize?.code,
      },
    });

    getOnboardPrices({
      address: formData?.split_address,
      house_size: formData?.houseSize,
      lawn_size: formData?.lawnSize,
    });
  };

  const handleOnChangeConfirmAddress = async (formData) => {
    saveOnboardData({ ohs: formData?.houseSize ?? {} });
    await createAddressOnboard(formData, true);
  };

  const handleAddressSubmit = async (formData) => {
    saveOnboardData({ oa: formData?.split_address });
    saveOnboardData({ ora: formData?.full_address });

    await createAddressOnboard(formData);
    setConfirmOnboardLocation(true);
  };

  useEffect(() => {
    clearOnboardData();
    setFormStep(1);
    setConfirmOnboardLocation(false);
    setAddress({});
  }, []);

  return (
    <>
      {isCalculating ? (
        <CardBody className={"min-w-[72vw] max-w-[100vw]"}>
          <div className="flex flex-col gap-y-5 mb-2 items-center">
            <Header $size="h3" $weight="600">
              Calculating your oneOS Home customized price...
            </Header>
            <div className="flex flex-col gap-y-5 mb-2 justify-center">
              <Header $size="h5" $weight="500" $color="#4B5563">
                It can take up to a minute for our AI to calculate your lawn and
                home square footage from your address.
              </Header>
            </div>
            <ProgressBar
              mode="indeterminate"
              style={{ height: "6px", width: "100%" }}
            />
          </div>
        </CardBody>
      ) : locationResponseCode && locationResponseCode === 406 ? (
        <LocationError responseCode={locationResponseCode} />
      ) : (
        <div className="flex flex-1 flex-col w-full h-fit items-center md:pb-16 px-5 bg-cardColor rounded-lg gap-y-5 pb-2">
          <div
            className={
              confirmOnboardLocation
                ? "flex flex-1 flex-col md:flex-row w-full h-fit justify-around px-5 xl:px-48"
                : "flex flex-1 flex-col md:flex-row lg:w-2/3 xl:w-full xl:max-w-[1000px] w-full h-fit justify-around px-5 xl:px-48"
            }
          >
            <div className="flex flex-1 flex-col gap-y-5 py-8 md:pt-24 sm:px-4 md:pr-7">
            <Header $size="h3" $weight="600" $align="left">
                {confirmOnboardLocation && locationResponse?.measurement_image
                  ? "Is this the home we are taking care of?"
                  : "Which home are we taking care of?"}
              </Header>
              {confirmOnboardLocation &&
                locationResponse?.measurement_image && (
                  <div className="md:hidden flex justify-center">
                    <img
                      alt="location image"
                      src={locationResponse?.measurement_image}
                    ></img>
                  </div>
                )}
              <Header $size="h5" $weight="600" $align="left">
                {confirmOnboardLocation
                  ? locationResponse?.measurement_image
                    ? "Please confirm that the highlighted home is yours and your home and lawn size."
                    : "Our AI could not calculate your lawn size, please enter your home and lawn size below."
                  : "Your exact oneOS Home pricing is determined by your home and lawn size. With your address, our AI can calculate your lawn square footage for you, and please select your home square footage in the next step."}
              </Header>
              {confirmOnboardLocation ? (
                <LocationConfirmForm
                  onSubmit={handleConfirmSubmit}
                  handleOnChangeConfirmAddress={handleOnChangeConfirmAddress}
                  address={address}
                  setAddress={setAddress}
                />
              ) : (
                <LocationForm
                  onSubmit={handleAddressSubmit}
                  address={address}
                  setAddress={setAddress}
                />
              )}
            </div>
            {confirmOnboardLocation && locationResponse?.measurement_image && (
              <div className="md:flex hidden flex-1 justify-center flex-col pt-24 sm:px-4 md:pl-7">
                <div className="flex justify-center">
                  <img
                    alt="location image"
                    src={locationResponse?.measurement_image}
                  ></img>
                </div>
              </div>
            )}
          </div>
          <Header $size="h3" $weight="600">
            Kansas City's Most Trusted Home Service Brands
          </Header>
          <img
            className="min-w-[100%] md:min-w-[600px] w-[80%] max-w-[600px]"
            src="/images/oos-trusted-trucks-cropped.png"
          />
        </div>
      )}
    </>
  );
};

export default OnboardLocationConfirm;
