import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import InputField from "components/InputField";
import { Button } from "primereact/button";
import useAuth from "api/useAuth";

const CreateAccountForm = ({ onSubmit }) => {
  const initialValues = {
    confirmPassword: "",
    password: "",
  };
  const { handleSignInWithRedirect } = useAuth();
  const {
    register,
    watch,
    setError,
    clearErrors,
    formState: { errors, isSubmitSuccessful },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: { ...initialValues },
  });
  const [password, confirmPassword] = watch(["password", "confirmPassword"]);

  useEffect(() => {
    if (!(confirmPassword?.length === 0) && !(password === confirmPassword)) {
      setError("confirmPassword", {
        type: "custom",
        message: "Passwords do not match.",
      });
    }
    if (password === confirmPassword) {
      clearErrors("confirmPassword");
    }
  }, [password, confirmPassword, setError, clearErrors]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({ password: "", confirmPassword: "" });
    }
  }, [isSubmitSuccessful, reset]);

  return (
    <form
      id="create-form"
      className="flex flex-col items-center"
      onSubmit={handleSubmit((formData) => {
        onSubmit(formData);
      })}
    >
      <div className="flex flex-col gap-y-5 full-width-xs w-[250px]">
        <InputField
          type="password"
          label="Create Password"
          placeholder=""
          register={register}
          registerName="password"
          required={"Password is required"}
          errorMessage={errors?.password?.message}
          maxLength={{ value: 99, message: "Maximum 99 characters" }}
          minLength={{ value: 8, message: "Minimum 8 characters" }}
          pattern={{
            value:
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,99}$/,
            message:
              "Minimum 8 characters, mix of upper and lower case, a special character: #,$,@,!,%,&,*,? and at least one number.",
          }}
        />

        <InputField
          type="password"
          label="Confirm Password"
          placeholder=""
          register={register}
          registerName="confirmPassword"
          required={"Confirm password is required"}
          errorMessage={errors?.confirmPassword?.message}
        />
        <div className="flex flex-row justify-center w-full">
          <Button type="submit" label="Continue" className="w-full" rounded />
        </div>
        <div className="relative pt-5 w-full flex flex-col gap-y-2 justify-center items-center border-t-2 border-mainColor overflow-visible">
          <p className="absolute top-[-0.75rem] mx-auto px-2 pb-5 bg-altBackgroundColor">
            OR
          </p>
          <Button
            rounded
            type="button"
            name="googleSignUpButton"
            className="w-full"
            onClick={() => {
              handleSignInWithRedirect({ provider: "Google" });
            }}
            label="Continue with Google"
            icon="pi pi-google"
          ></Button>
        </div>
      </div>
    </form>
  );
};

export default CreateAccountForm;
