import React from "react";
import { InputText } from "primereact/inputtext";
import StyledLabel from "./components/StyledLabel";
import StyledErrorMsg from "./components/StyledErrorMsg";

const InputField = ({
  label,
  register,
  required,
  pattern,
  maxLength,
  minLength,
  errorMessage,
  registerName,
  placeholder,
  disabled,
  type,
}) => (
  <div className="flex flex-col w-full gap-y-1.5">
    <StyledLabel>{label}</StyledLabel>
    <InputText
      placeholder={placeholder}
      type={type ? type : "text"}
      {...register(registerName, { required, maxLength, pattern, minLength })}
      className="p-inputtext-sm"
      disabled={disabled}
    />
    {errorMessage && <StyledErrorMsg>{errorMessage}</StyledErrorMsg>}
  </div>
);

export default InputField;
