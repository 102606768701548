export const houseSizeOptions = [
  { size: "0-1800 sqft", code: "house_0_1800" },
  { size: "1801-3500 sqft", code: "house_1801_3500" },
  { size: "3501-4500 sqft", code: "house_3501_4500" },
  { size: "4501-6500 sqft", code: "house_4501_6500" },
  { size: "6501+ sqft", code: "house_6501_plus" },
];
export const lawnSizeOptions = [
  { size: "0-1000 sqft", code: "lawn_0_1000" },
  { size: "1001-2000 sqft", code: "lawn_1001_2000" },
  { size: "2001-3000 sqft", code: "lawn_2001_3000" },
  { size: "3001-4000 sqft", code: "lawn_3001_4000" },
  { size: "4001-5000 sqft", code: "lawn_4001_5000" },
  { size: "5001-6000 sqft", code: "lawn_5001_6000" },
  { size: "6001-7000 sqft", code: "lawn_6001_7000" },
  { size: "7001-8000 sqft", code: "lawn_7001_8000" },
  { size: "8001-9000 sqft", code: "lawn_8001_9000" },
  { size: "9001-10000 sqft", code: "lawn_9001_10000" },
  { size: "10001-11000 sqft", code: "lawn_10001_11000" },
  { size: "11001-12000 sqft", code: "lawn_11001_12000" },
  { size: "12001-13000 sqft", code: "lawn_12001_13000" },
  { size: "13001-14000 sqft", code: "lawn_13001_14000" },
  { size: "14001-15000 sqft", code: "lawn_14001_15000" },
  { size: "15001-16000 sqft", code: "lawn_15001_16000" },
  { size: "16001-17000 sqft", code: "lawn_16001_17000" },
  { size: "17001-18000 sqft", code: "lawn_17001_18000" },
  { size: "18001-19000 sqft", code: "lawn_18001_19000" },
  { size: "19001-20000 sqft", code: "lawn_19001_20000" },
  { size: "20001-21000 sqft", code: "lawn_20001_21000" },
  { size: "21001-22000 sqft", code: "lawn_21001_22000" },
  { size: "22001-23000 sqft", code: "lawn_22001_23000" },
  { size: "23001-24000 sqft", code: "lawn_23001_24000" },
  { size: "24001-25000 sqft", code: "lawn_24001_25000" },
  { size: "25001-26000 sqft", code: "lawn_25001_26000" },
  { size: "26001-27000 sqft", code: "lawn_26001_27000" },
  { size: "27001-28000 sqft", code: "lawn_27001_28000" },
  { size: "28001-29000 sqft", code: "lawn_28001_29000" },
  { size: "29001-30000 sqft", code: "lawn_29001_30000" },
  { size: "30001-31000 sqft", code: "lawn_30001_31000" },
  { size: "31001-32000 sqft", code: "lawn_31001_32000" },
  { size: "32001-33000 sqft", code: "lawn_32001_33000" },
  { size: "33001-34000 sqft", code: "lawn_33001_34000" },
  { size: "34001-35000 sqft", code: "lawn_34001_35000" },
  { size: "35001-36000 sqft", code: "lawn_35001_36000" },
  { size: "36001-37000 sqft", code: "lawn_36001_37000" },
  { size: "37001-38000 sqft", code: "lawn_37001_38000" },
  { size: "38001-39000 sqft", code: "lawn_38001_39000" },
  { size: "39001-40000sqft", code: "lawn_39001_40000" },
  { size: "40001-41000 sqft", code: "lawn_40001_41000" },
  { size: "41001-42000 sqft", code: "lawn_41001_42000" },
  { size: "42001-43000 sqft", code: "lawn_42001_43000" },
  { size: "43001-44000 sqft", code: "lawn_43001_44000" },
  { size: "44001-45000 sqft", code: "lawn_44001_45000" },
  { size: "45001-46000 sqft", code: "lawn_45001_46000" },
  { size: "47001_48000 sqft", code: "lawn_47001_48000" },
  { size: "48001-49000 sqft", code: "lawn_48001_49000" },
  { size: "49001-50000 sqft", code: "lawn_49001_50000" },
  { size: "50001-60000 sqft", code: "lawn_50001_60000" },
  { size: "60001+ sqft", code: "lawn_60001_plus" },
];
