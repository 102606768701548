import { createContext, useCallback, useState } from "react";
import { isEmptyObject } from "utils/functions";
export const OnboardContext = createContext();

export const OnboardContextProvider = ({ children }) => {
  const [formStep, setFormStep] = useState(0);
  const [onboardData, setOnboardData] = useState({});

  // Show a special loader when calculating address
  const [isCalculating, setIsCalculating] = useState(false);
  const [isConfirmingNewPassword, setIsConfirmingNewPassword] = useState(false);
  const [confirmOnboardLocation, setConfirmOnboardLocation] = useState(false);
  const [locationResponseCode, setLocationResponseCode] = useState(null);
  const [locationResponse, setLocationResponse] = useState(null);

  
  // IF SessionStorage IS NOT SUPPORTED THEN USER CANNOT REFRESH DURING ONBOARD PROCESS
  const saveOnboardData = (data) => {
    setOnboardData({
      ...onboardData,
      ...data,
    });
    //TODO: CHECK IF SS is supported
    const current = JSON.parse(sessionStorage.getItem("tempOnboard"));
    sessionStorage.setItem(
      "tempOnboard",
      JSON.stringify({ ...current, ...data })
    );
  };
  
  const getOnboardData = (key) => {
    //TODO: review this
    if (!isEmptyObject(onboardData) && onboardData[key]) {
      return onboardData[key];
    } else if (sessionStorage.getItem("tempOnboard")) {
      const parsedData = JSON.parse(sessionStorage.getItem("tempOnboard"));
      return parsedData[key];
    }
  };

  // End User Custom Package Selection
  const [customPackageSelection, _setCustomPackageSelect] = useState(getOnboardData("ps") ?? {
    essentials: {},
    complete: {},
    toggleComplete: false,
  });
  
  const clearOnboardData = () => {
    sessionStorage.removeItem("tempOnboard");
    setOnboardData({});
    _setCustomPackageSelect({
      essentials: {},
      complete: {},
      toggleComplete: false,
    });
  };


  const setCustomPackageSelect = useCallback((newValue) => {
    saveOnboardData({ ps: newValue });
    _setCustomPackageSelect(newValue);
  }, [customPackageSelection]);

  return (
    <OnboardContext.Provider
      value={{
        formStep,
        setFormStep,
        onboardData,
        saveOnboardData,
        clearOnboardData,
        getOnboardData,
        isCalculating,
        setIsCalculating,
        locationResponseCode,
        setLocationResponseCode,
        customPackageSelection,
        setCustomPackageSelect,
        isConfirmingNewPassword,
        setIsConfirmingNewPassword,
        locationResponse,
        setLocationResponse,
        confirmOnboardLocation,
        setConfirmOnboardLocation,
      }}
    >
      {children}
    </OnboardContext.Provider>
  );
};
