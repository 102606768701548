import React, { useEffect, useContext } from "react";
import { Outlet, useLocation, matchPath } from "react-router-dom";
import OnboardNav from "./components/OnboardNav";
import { useNavigate } from "react-router-dom";
import { Context } from "providers/Context";

const Onboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { openInfoSnackbar } = useContext(Context);

  //Check if the user is on a data dependent path and tempOnboard has not been set, if not been set, redirect to onboard with snackbar
  useEffect(() => {
    const INFO_MESSAGE = "Address not found in session, returning to start";
    const tempOnboard = sessionStorage.getItem("tempOnboard");

    //Paths that are data independent (Don't require tempOnboard to be set)
    const dataIndependentPaths = ["/onboard"];
    const isDataIndependent = dataIndependentPaths.some(path => matchPath(path, location.pathname));
    
    if (!tempOnboard && !isDataIndependent) {
      openInfoSnackbar(INFO_MESSAGE);
      navigate("/onboard");
    }

  }, [openInfoSnackbar, navigate, location.pathname]);

  return (
    <div className="w-full h-[100vh]">
      <OnboardNav />
      <div className="onboard-body">
        <Outlet />
      </div>
    </div>
  );
};
export default Onboard;
