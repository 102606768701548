import React, { useEffect, useContext } from "react";
import { useState } from "react";
import { Context } from "providers/Context";
import CardBody from "components/CardBody";
import Header from "components/Header";
import { Card } from "primereact/card";
import AccountForm from "./components/AccountForm";
import ChangePasswordForm from "./components/ChangePasswordForm";
import useApi from "api/useApi";

const Account = () => {
  const { getUserInfoApi } = useApi();
  const { userData, setIsLoading } = useContext(Context);
  const [addresses, setAddresses] = useState(
    userData && userData?.addresses ? userData?.addresses : []
  );

  useEffect(() => {
    const fun = async () => {
      setIsLoading(true);
      await getUserInfoApi();
      setIsLoading(false);
    };
    fun();
  }, []);

  useEffect(() => {
    setAddresses(userData && userData?.addresses);
  }, [userData]);
  return (
    <div className="flex flex-grow flex-col gap-y-7 items-start">
      <CardBody className={"w-full"}>
        <div className="flex flex-col gap-y-5">
          <Header $size="h3" $weight="600">
            Profile
          </Header>
          {addresses?.map((item, index) => (
            <Card key={index} className="p-card-gray">
              {index === 0 && (
                <Header
                  $size="h5"
                  $weight="700"
                  $color="#374151"
                  $display={"inline"}
                >
                  Primary Address:
                </Header>
              )}
              {` ${item?.street_address}, ${item?.city}, ${item?.state} ${item?.postal_code}`}
            </Card>
          ))}
        </div>
      </CardBody>
      <div className="flex flex-row flex-wrap justify-between gap-x-10 gap-y-7 w-full">
        <CardBody className={"w-full"}>
          <div className="flex flex-col gap-y-5">
            <Header $size="h3" $weight="600">
              Account Details
            </Header>
            <AccountForm user={userData} />
          </div>
        </CardBody>
        <CardBody className={"w-full"}>
          <div className="flex flex-col gap-y-5">
            <Header $size="h3" $weight="600">
              Change Password
            </Header>
            <ChangePasswordForm />
          </div>
        </CardBody>
      </div>
    </div>
  );
};

export default Account;
