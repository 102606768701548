import React, { useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { PatternFormat } from "react-number-format";
import InputField from "components/InputField";
import UnregisteredField from "components/UnregisteredField";
import { Button } from "primereact/button";
import { Context } from "providers/Context";

const ContactUserForm = ({ onSubmit }) => {
  const navigate = useNavigate();
  const { isAuthorized, isOnboarded, currentSession } = useContext(Context);
  const initialValues = {
    email: isAuthorized ? currentSession?.tokens?.idToken.payload?.email : "",
    phone: "",
  };
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: { ...initialValues },
  });

  return (
    <form id="onboard-contact-form" className="flex flex-col items-center">
      <div className="flex flex-col gap-y-5 full-width-xs w-fit">
        <div className="flex flex-col justify-center self-center gap-y-2.5 w-[250px]">
          <InputField
            register={register}
            registerName={"email"}
            disabled={isAuthorized && isOnboarded === false}
            type={"email"}
            label="Email Address"
            placeholder=""
            required={"Email is required"}
            pattern={{
              value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
              message: "Enter a valid email address",
            }}
            errorMessage={errors?.email?.message}
          />
          <Controller
            control={control}
            name={"phone"}
            rules={{
              required: "Phone is required",
              pattern: {
                value: /^\d{10}$/,
                message: "Ten digits required",
              },
            }}
            render={({ field: { onChange, name } }) => (
              <PatternFormat
                format="(###) ### ####"
                name={name}
                label="Phone Number"
                placeholder=""
                onValueChange={(value) => {
                  onChange(value?.value);
                }}
                errorMessage={errors?.phone?.message}
                customInput={UnregisteredField}
              />
            )}
          />
          <div className="flex flex-row justify-center w-full">
            <span className="text-xs">10 digits, US phone numbers only</span>
            </div>
        </div>
        <div className="flex flex-col justify-center self-center gap-y-2.5">
          <div className="flex flex-row justify-center w-fit max-w-[40vw]">
            <p className=" text-[14.5px] text-[#000000]">By clicking “Submit” I provide my signature consenting to contact from SWVE Management Holdco, LLC  and its subsidiary companies at the number, email, and physical address I provided regarding products or services via live,  automated or prerecorded call, or via text. Consent is not a condition of purchase. I  can revoke this consent by emailing consent@wearesouthwind.com. I agree to the <a className=" text-blue-500 underline hover:text-blue-700" href="https://oneoshome.com/privacy" target="_blank">Privacy Policy</a> and <a className=" text-blue-500 underline hover:text-blue-700" href="https://oneoshome.com/terms" target="_blank">Terms of Use</a> including mandatory arbitration.</p>
          </div>
        </div>
        <div className="flex flex-col justify-center gap-y-2.5">
          <div className="flex flex-row justify-center">
            <Button
              label="Submit"
              onClick={handleSubmit((formData) => {
                onSubmit(formData);
              })}
              rounded
            />
          </div>
          <div className="flex flex-row justify-center">
            <Button
              onClick={(e) => {
                e.preventDefault();
                navigate("/onboard/custom-package");
              }}
              label="Back"
              link
            />
          </div>
        </div>
      </div>
    </form>
  );
};
export default ContactUserForm;
