import React, {useEffect, useState, useRef} from 'react'
import useApi from 'api/useApi';

import { SelectButton } from 'primereact/selectbutton';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { InputText } from "primereact/inputtext";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import Header from 'components/Header';
import { Dialog } from 'primereact/dialog';

const Membership = () => {
    const [value, setValue] = useState(null);
    const { getPurchasesAndProductsApi, createContactUsMember } = useApi();
    const [products, setProducts] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const searchRef = useRef(null);

    const calculateTotal = (values) => {
        return values.reduce((total, value) => {
            return total + value;
        }, 0);
    };
    
    const formatCurrency = (value) => {
        return Number(Number(value).toFixed(2)).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    };

    const header = 
    <div className='flex flex-row flex-wrap justify-between w-full items-center'>
        <div className='flex flex-row flex-nowrap max-[768px]:flex-col max-[768px]:justify-start max-[768px]:items-start justify-between w-full items-center py-4 pl-4'>
            <h1>Products Included</h1>
            <span className='flex flex-row flex-nowrap justify-start items-center gap-2' id='search-services-container'>
                <i className="pi pi-search"></i>
                <InputText id='search-services' placeholder="Search Services" ref={searchRef} />
            </span>
        </div>
        <div className='flex flex-row flex-nowrap justify-between w-full items-center py-4 px-4'>
            <h1>Product Category</h1>
            <div className='flex flex-row flex-nowrap justify-end items-center py-4 pr-6 gap-4'>
                <h1>Annual Services</h1>
                <h1>Upgrade</h1>
            </div>
        </div>
    </div>

    const [options, setOptions] = useState([
        {label: 'Price', value: ''}, 
        {label: 'Next Billing Cycle', value: ''},
    ]);

    const splitItemTemplate = (option) => {
        return (
            <div className='split-button-content min-[768px]:flex min-[768px]:flex-1'>
                <div>{option.label}</div>
                <span className='p-button-label'>{option.value}</span>
            </div>
        )
    }

    const listTemplate = (items) => {
        if (!items || Object.keys(items).length === 0) return null;

        let list = Object.keys(items).map((key, index) => {
            return itemTemplate(items[key], index);
        });

        return <div className="grid grid-nogutter">{list}</div>;
    };

    const nestedSavingsTemplate = (product) => {
        return (
            <span>${formatCurrency((product.savings_amount / 100) * 12)}</span>
        )
    }

    // Update searchValue when searchRef changes
    useEffect(() => {
        const handleInputChange = () => {
            setSearchValue(searchRef.current.value);
        };

        const inputElement = searchRef.current;
        inputElement.addEventListener('input', handleInputChange);

        return () => {
            inputElement.removeEventListener('input', handleInputChange);
        };
    }, []);

    const includedTemplate = (product) => {
        return (
            <span>
                <span className='mr-2'>{product.name}</span>
                {product.is_add_on && <Tag value="Add On" severity="info"></Tag>}
            </span>
        )
    }

    const itemTemplate = (productArr, index) => {

        // Return null if the search value is not found in product.price_category
        if (searchValue && !new RegExp(searchValue, 'i').test(productArr[0].price_category)) {
            return null;
        }

        return (
            <div className="col-12" key={index}>
                <div className={`flex flex-row flex-nowrap w-full justify-between items-center py-4 px-4 ${index % 2 === 1 ? 'bg-[#F5F5F5]' : ''}`}>
                    <span className='flex flex-row flex-nowrap justify-start items-center gap-4'>
                        <Button icon={ expanded.includes(index) ? "pi pi-chevron-down" : "pi pi-chevron-right" } rounded link style={{color: 'gray', padding: '0', margin: '0'}} onClick={() => {
                            if(expanded.includes(index)) {
                                setExpanded(expanded.filter((i) => i !== index));
                            } else {
                                setExpanded([...expanded, index]);
                            }
                        }}/>
                        <div>{productArr[0].price_category}</div>
                    </span>
                    <div className='flex flex-grow flex-row flex-nowrap justify-end items-center gap-4'>
                        <div className='w-fit whitespace-nowrap'>{productArr.length > 1 ? "Expand for details" : productArr[0].annual_services}</div>
                        <Button label="Upgrade" link onClick={() => {
                            createContactUsMember({
                                comments: `Request to upgrade product: "${productArr[0].price_category}"`
                            })
                            setShowDialog(true);
                        }} />
                    </div>
                </div>
                {expanded.includes(index) && <div className='p-5 nested-table'>
                    <DataTable value={productArr} >
                        <Column header="What's included" body={includedTemplate} />
                        {productArr.length > 1 && <Column field="annual_services" header="Annual Services" />}
                        <Column field="savings_amount" header="Annual Savings" body={nestedSavingsTemplate} />
                    </DataTable>
                </div>}
            </div>
        );
        return <></>
    };

    useEffect(() => {
        getPurchasesAndProductsApi().then((response) => {
            if(response?.data?.length > 0) {
                let nextBillingDate = new Date(response.data[0].membership.current_period_end);
                // Extract the month, day, and year
                const month = (nextBillingDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
                const day = nextBillingDate.getDate().toString().padStart(2, '0');
                const year = nextBillingDate.getFullYear().toString().slice(-2); // Get last two digits of the year

                setOptions([
                    {label: 'Price', value: `$${formatCurrency(calculateTotal(response?.data[0]?.price.map((product) => product.amount)) / 100)}/mo`}, 
                    {label: 'Next Billing Cycle', value: `${month}/${day}/${year}`},
                ]);

                const formattedProducts = {};
                response?.data[0]?.price?.forEach((product) => {
                    if(!Array.isArray(formattedProducts[product.price_category])){
                        formattedProducts[product.price_category] = [];
                    }
                    formattedProducts[product.price_category].push(product);
                })

                setProducts(formattedProducts);
            }
        })
    }, [])

  return (
    <>
        <div className='flex flex-col flex-nowrap w-full gap-5'>
            <div className='flex flex-row flex-nowrap justify-start items-center gap-4'>
                <Header $size="h4" $weight="700">My Membership</Header>
                <Button label="Modify" onClick={() => {
                    createContactUsMember({
                        comments: "Request to modify membership"
                    })
                    setShowDialog(true);
                }} />
            </div>
            <div>
                <SelectButton className='settings-split-button' value={value} onChange={(e) => setValue(null)} itemTemplate={splitItemTemplate} options={options} />
            </div>
            <div className='max-w-full overflow-x-auto'>
                <Card id='settings-table'>       
                    <DataView value={products} listTemplate={listTemplate} header={header}/>
                </Card>
            </div>
        </div>
        <Dialog header="Contact" visible={showDialog} style={{ width: '30rem' }} onHide={() => {if (!showDialog) return; setShowDialog(false); }}>
            <div className='flex flex-col flex-nowrap justify-start w-full gap-4'>
                <div>
                    <i className="pi pi-phone text-[#0070f3]"></i>
                    <p>One of our customer agents will contact you shortly to update your subscription.</p>
                </div>
                <Button label="Close" link style={{color: 'gray', width: 'fit-content'}} onClick={() => setShowDialog(false)} />
            </div>
        </Dialog>
    </>


  )
}

export default Membership