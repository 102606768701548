/** General Helper functions */

// checks to see if an object is empty
export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0;
};

// checks if cookie is set
export const isCookieSet = (name) => {
  let decodedCookie = decodeURIComponent(document.cookie);
  let cookieArray = decodedCookie.split(";");
  let findCookie = cookieArray.find((cookie) => {
    return cookie.trimStart().includes(name);
  });
  return findCookie ? true : false;
};
